import { useWatch } from "react-hook-form";
import { useRealEstateTitle } from "hooks/use-realEstate-title/useRealEstateTitle";

export const useGenerateTitle = (): string => {
  const [city, locationType, surface, totalLots, type] = useWatch({
    name: ["address.city", "locationType", "surface", "totalLots", "type"],
  });

  return useRealEstateTitle({
    city,
    locationType,
    surface,
    totalLots,
    type,
  });
};
