import { useState, useEffect, useCallback } from "react";

import { styled } from "@mui/material/styles";
import { useListContext } from "react-admin";
import { Box } from "@mui/material";

import { FiltersActions } from "./FiltersActions";

const Container = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 8px;
`;

const FiltersContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 8px;
  overflow: auto;
  margin: 0;
  padding: 0;
`;

export const UsersFilters: React.FC = () => {
  const { setFilters: setRAFilters, filterValues } = useListContext();

  const [filters, setFilters] = useState(filterValues);

  useEffect(() => {
    setFilters(filterValues);
  }, [filterValues]);

  const handleApplyFilters = useCallback(() => {
    setRAFilters && setRAFilters(filters, []);
    setFilters(() => ({}));
  }, [filters, setRAFilters]);

  const handleResetFilters = useCallback(() => {
    setRAFilters && setRAFilters({}, []);
    setFilters(() => ({}));
  }, [setRAFilters]);

  return (
    <Container>
      <FiltersContainer></FiltersContainer>
      <FiltersActions
        applyFilters={handleApplyFilters}
        resetFilters={handleResetFilters}
      />
    </Container>
  );
};
