import { ImageField } from "ui/organisms/image-field/ImageField";
import { AccordionForm } from "ui/molecules/accordion-form/AccordionForm";
import { useTranslate } from "react-admin";

import { RealEstateEditAccordeonFormProps } from "./RealEstateEditAccordeonFormProps";

export const MarketDataForm: React.FC<RealEstateEditAccordeonFormProps> = ({
  defaultExpanded,
}) => {
  const translate = useTranslate();

  return (
    <AccordionForm
      defaultExpanded={defaultExpanded}
      title={translate("realEstate.form.marketData")}
    >
      <ImageField source="images" />
    </AccordionForm>
  );
};
