import React, { useMemo } from "react";

import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  useTranslate,
  Edit as RAEdit,
  useNotify,
  useGetList,
} from "react-admin";
import { Modal } from "ui/organisms/modal/Modal";
import { Button } from "ui/atoms/button/Button";
import { DataCitiesManager } from "types/user.types";

import { UserForm } from "./UserForm";
import { formatCitiesManager } from "./formatCitiesManager.utils";

const FooterContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const Edit = styled(RAEdit)`
  & .MuiPaper-root {
    box-shadow: none;
    border: none;
  }
`;

const ButtonsContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: ${(props) => props.theme.spacing(2)};
`;

interface UserModalFormProps {
  onClose: () => void;
  id: string;
  openModalConfirmDelete: () => void;
}

export const EditUserModalForm: React.FC<UserModalFormProps> = ({
  onClose,
  id,
  openModalConfirmDelete,
}) => {
  const { data: dataCitiesManager } = useGetList("users/citiesmanagers");

  const translate = useTranslate();
  const notify = useNotify();

  const computedCitiesManager = useMemo(
    () => formatCitiesManager(dataCitiesManager as DataCitiesManager[]),
    [dataCitiesManager]
  );

  const onSuccess = () => {
    notify("user.form.edit.successToaster", { type: "success" });
    onClose();
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onError = (err: any) => {
    notify(err?.body?.message || "user.edit.error", {
      type: "error",
    });
  };

  return (
    <Modal
      open={true}
      onClose={onClose}
      title={translate("users.edit")}
      showCloseButton={true}
      footer={
        <FooterContainer>
          <ButtonsContainer>
            <Button
              variant="text"
              onClick={() => {
                openModalConfirmDelete();
              }}
            >
              {translate("ra.action.delete")}
            </Button>
            <Button
              variant="text"
              type="submit"
              form="user-form"
              data-testid="user-form-submit-button"
            >
              {translate("ra.action.save")}
            </Button>
          </ButtonsContainer>
        </FooterContainer>
      }
    >
      <Edit
        id={id}
        resource="users"
        mutationMode="pessimistic"
        mutationOptions={{ onError, onSuccess }}
      >
        <UserForm isEdit={true} cityManagers={computedCitiesManager} />
      </Edit>
    </Modal>
  );
};
