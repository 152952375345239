import React from "react";

import { useTranslate } from "react-admin";
import { useFormContext } from "react-hook-form";
import { LoadingButton } from "ui/molecules/loading-button/LoadingButton";
import { useRealEstateActions } from "features/realEstates/edit/hooks/useRealEstateActions";

export const PublishButton: React.FC = () => {
  const translate = useTranslate();
  const formContext = useFormContext();
  const { askPublish, doPublish } = useRealEstateActions(formContext);

  const handleAskPublish = () => {
    askPublish.mutate();
  };

  const handleDoPublish = () => {
    doPublish.mutate();
  };

  return (
    <>
      {!!askPublish.isAllowed && (
        <LoadingButton variant="outlined" onClick={handleAskPublish}>
          {translate("realEstate.action.askPublish.label")}
        </LoadingButton>
      )}
      {!!doPublish.isAllowed && (
        <LoadingButton variant="outlined" onClick={handleDoPublish}>
          {translate("realEstate.action.doPublish.label")}
        </LoadingButton>
      )}
    </>
  );
};
