import * as yup from "yup";
import {
  addressSchema,
  propertyKindSchema,
  propertyLangSchema,
} from "features/realEstates/validators/realEstate.validator";

export const propertyForm = yup.object().shape({
  address: addressSchema,
  lang: propertyLangSchema,
  type: propertyKindSchema,
});
