import { AccordionForm } from "ui/molecules/accordion-form/AccordionForm";
import { Fieldset } from "ui/molecules/fieldset/Fieldset";
import { TextInput, useTranslate } from "react-admin";
import { ImageField } from "ui/organisms/image-field/ImageField";

import { RealEstateEditAccordeonFormProps } from "./RealEstateEditAccordeonFormProps";

export const OverviewForm: React.FC<RealEstateEditAccordeonFormProps> = ({
  defaultExpanded,
}) => {
  const translate = useTranslate();

  return (
    <AccordionForm
      defaultExpanded={defaultExpanded}
      title={translate("realEstate.form.overview")}
    >
      <Fieldset>
        <TextInput
          margin="none"
          variant="outlined"
          label="realEstate.input.url3D"
          source="url3D"
        />
      </Fieldset>
      <Fieldset title="image" />
      <ImageField source="coverImage" maxFiles={1} />
    </AccordionForm>
  );
};
