import { SelectInput, useStore, useTranslate } from "react-admin";
import { FieldValues } from "react-hook-form";
import { ActionModal } from "components/action-modal/ActionModal";
import { useRealEstateActions } from "features/realEstates/edit/hooks/useRealEstateActions";
import { yupResolver } from "@hookform/resolvers/yup";
import { propertyArchiveForm } from "features/realEstates/edit/RealEstateEdit.validator";

import { ARCHIVING_REASONS_DATA } from "constants/real-estates";

export const ArchiveActionModal: React.FC = () => {
  const translate = useTranslate();
  const [isModalOpen, showModal] = useStore(
    "realEstate.edit.show_archive_modal",
    false
  );

  const { archive } = useRealEstateActions();

  const handleCloseModal = () => {
    showModal(false);
  };

  const handleCallback = ({ archivingReason }: FieldValues) => {
    archive.mutate({ archivingReason });
    handleCloseModal();
  };

  return (
    <ActionModal
      slug="archive"
      resolver={yupResolver(propertyArchiveForm)}
      isOpen={isModalOpen}
      title={translate("realEstate.action.archive.form_title")}
      saveLabel={translate("realEstate.action.archive.submit")}
      onClose={handleCloseModal}
      callback={handleCallback}
    >
      <SelectInput
        fullWidth
        label="realEstate.action.archive.input_archivingReason"
        variant="outlined"
        source="archivingReason"
        choices={ARCHIVING_REASONS_DATA}
      />
    </ActionModal>
  );
};
