import { styled } from "@mui/material/styles";
import { AppBar as MUIAppBar, Toolbar as MUIToolbar } from "@mui/material";

const Toolbar = styled(MUIToolbar)<{ logo: string }>`
  background-color: #000;
  justify-content: space-between;
  background-size: 56px;
  background-repeat: no-repeat;
  background-image: url(${({ logo }) => logo});
  background-position: center;
  flex-direction: row-reverse;
`;

const StyledAppBar = styled(MUIAppBar)`
  z-index: ${({ theme }) => theme.zIndex.drawer + 1};
`;

export const AppBar: React.FC<React.PropsWithChildren> = (props) => {
  const { children } = props;

  return (
    <StyledAppBar position="fixed">
      <Toolbar logo={process.env.PUBLIC_URL + "/logo192.png"}>
        {children}
      </Toolbar>
    </StyledAppBar>
  );
};
