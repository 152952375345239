import {
  House,
  Logout,
  Help,
  People,
  SvgIconComponent,
} from "@mui/icons-material";
import { useLogout, usePermissions, useRedirect } from "react-admin";
import {
  Menu as MenuUi,
  MenuUiProps,
  MenuItemProps,
} from "ui/organisms/menu/Menu";

import { URI } from "constants/uri";

export type MenuProps = Pick<MenuUiProps, "variant" | "open" | "onClose">;

export const Menu: React.FC<MenuProps> = ({ open, onClose, variant }) => {
  const logout = useLogout();
  const redirect = useRedirect();
  const { permissions } = usePermissions();
  const [, urlParameters] = window.location.pathname.split("/");

  const allowedUsersManagers = ["admin", "dev", "hunterManager"];
  const canSeeUsers =
    Array.isArray(permissions) &&
    allowedUsersManagers.some((right) => permissions.includes(right));

  const menuItems: Array<
    Partial<MenuItemProps> & {
      selectedURI: string[];
      uri: string;
      Icon: SvgIconComponent;
    }
  > = [
    {
      Icon: House,
      id: 1,
      selectedURI: [URI.realEstates, URI.home],
      uri: URI.home,
    },
  ];

  if (canSeeUsers) {
    menuItems.push({
      Icon: People,
      id: 2,
      selectedURI: [URI.users],
      uri: URI.users,
    });
  }
  menuItems.push({
    Icon: Help,
    id: 3,
    selectedURI: [URI.help],
    uri: URI.help,
  });

  const computedMenuItems: MenuItemProps[] = menuItems.map((menuItem) => ({
    ...menuItem,
    onClick: () => redirect(menuItem.uri),
    selected: menuItem.selectedURI.some((e) => e === `/${urlParameters}`),
  }));

  const footerItem = {
    Icon: Logout,
    onClick: logout,
  } as MenuItemProps;

  return (
    <MenuUi
      open={open}
      onClose={onClose}
      variant={variant}
      items={computedMenuItems}
      footerItem={footerItem}
    />
  );
};
