import React, { useState } from "react";

import { useFormContext } from "react-hook-form";

import {
  useArchiveAction,
  ActionMutationReturn,
} from "../hooks/useArchiveAction";
import { usePublishActions } from "../hooks/usePublishActions";

export type ActionContextProps = {
  archive: ActionMutationReturn<unknown, unknown, unknown, unknown>;
  askPublish: ActionMutationReturn<unknown, unknown, void, unknown>;
  doPublish: ActionMutationReturn<unknown, unknown, void, unknown>;
  setFormContext: React.Dispatch<ReturnType<typeof useFormContext>>;
};

export const ActionContext = React.createContext<ActionContextProps>(
  {} as ActionContextProps
);

/**
 * Exposes all custom action mutation ( and their status: 'idle', 'loading', 'errors' ... under the ActionContext )
 */
export const ActionContextProvider: React.FC<{
  children: JSX.Element | JSX.Element[];
}> = ({ children }) => {
  const [formContext, setFormContext] =
    useState<ReturnType<typeof useFormContext>>();
  const { askPublish, doPublish } = usePublishActions(formContext);

  const actions: ActionContextProps = {
    archive: useArchiveAction(),
    askPublish,
    doPublish,
    setFormContext,
  };

  return (
    <ActionContext.Provider value={actions}>{children}</ActionContext.Provider>
  );
};
