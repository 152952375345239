import { Dispatch, SetStateAction, useCallback, useMemo } from "react";

import { styled } from "@mui/material/styles";
import { FormGroup, Chip, Box } from "@mui/material";
import { useTranslate, Form as RAForm } from "react-admin";
import { AutocompleteValue } from "ui/molecules/autocomplete-input/AutocompleteInput";
import { CollapsibleSection } from "ui/molecules/collapsible-section/CollapsibleSection";
import { GooglePlacesAutocomplete } from "components/google-places-autocomplete/GooglePlacesAutocomplete";
import { capitalizeFirstLetter } from "utils/strings/strings.utils";
import type { RawGooglePlaceSuggestion } from "components/google-places-autocomplete/GooglePlacesAutocomplete.types";

import {
  STATUSES,
  PROPERTY_KINDS,
  RENTING_KINDS,
} from "constants/real-estates";

import { CheckboxGroup } from "../checkbox-group/CheckboxGroup";
import { FiltersRangeGroup } from "../filters-range-group/FiltersRangeGroup";
import { HuntersAutocomplete } from "../HuntersAutocomplete";
import { CitiesAutocomplete } from "../CitiesAutocomplete";
import { updateHunters, updateCities } from "./FiltersBody.utils";
import {
  IRealEstatesFilters,
  HunterData,
  CityData,
} from "../RealEstateFilters.types";

const Form = styled(RAForm)`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ChipContainer = styled(Box)`
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
`;

interface FilterContentProps {
  filters: IRealEstatesFilters;
  setFilters: Dispatch<SetStateAction<IRealEstatesFilters>>;
}

const isChecked = (value: string, filters?: string[]): boolean => {
  return !!(filters && filters?.includes(value));
};

export const FilterBody: React.FC<FilterContentProps> = (props) => {
  const { setFilters, filters } = props;
  const translate = useTranslate();

  const statusesCheckboxes = [
    {
      checked: isChecked(STATUSES.DRAFT, filters?.status),
      label: translate("realEstate.status.draft"),
      value: STATUSES.DRAFT,
    },
    {
      checked: isChecked(STATUSES.IN_REVIEW, filters?.status),
      label: translate("realEstate.status.in_review"),
      value: STATUSES.IN_REVIEW,
    },
    {
      checked: isChecked(STATUSES.AVAILABLE, filters?.status),
      label: translate("realEstate.status.available"),
      value: STATUSES.AVAILABLE,
    },
    {
      checked: isChecked(STATUSES.TRANSACTION, filters?.status),
      label: translate("realEstate.status.transaction"),
      value: STATUSES.TRANSACTION,
    },
    {
      checked: isChecked(STATUSES.SOLD, filters?.status),
      label: translate("realEstate.status.sold"),
      value: STATUSES.SOLD,
    },
    {
      checked: isChecked(STATUSES.ARCHIVED, filters?.status),
      label: translate("realEstate.status.archived"),
      value: STATUSES.ARCHIVED,
    },
  ];

  const typesCheckboxes = [
    {
      checked: isChecked(PROPERTY_KINDS.APPARTMENT, filters?.types),
      label: translate("realEstate.type.appartment"),
      value: PROPERTY_KINDS.APPARTMENT,
    },
    {
      checked: isChecked("HOUSE", filters?.types),
      label: translate("realEstate.type.house"),
      value: PROPERTY_KINDS.HOUSE,
    },
    {
      checked: isChecked("BUILDING", filters?.types),
      label: translate("realEstate.type.building"),
      value: PROPERTY_KINDS.BUILDING,
    },
  ];

  const rentingTypesCheckboxes = [
    {
      checked: isChecked(RENTING_KINDS.SIMPLE, filters?.rentingTypes),
      label: translate("realEstate.location.type.simple"),
      value: RENTING_KINDS.SIMPLE,
    },
    {
      checked: isChecked(RENTING_KINDS.COLOCATION, filters?.rentingTypes),
      label: translate("realEstate.location.type.colocation"),
      value: RENTING_KINDS.COLOCATION,
    },
  ];

  const onUpdateHunters = useCallback(
    (hunter: Nullable<AutocompleteValue<HunterData>>) => {
      const updatedFilters = updateHunters(filters, hunter);
      setFilters(updatedFilters);
    },
    [filters, setFilters]
  );

  const handleRemoveHunter = useCallback(
    (hunterId: string) => {
      setFilters((prevFilters: IRealEstatesFilters) => ({
        ...prevFilters,
        hunters: prevFilters?.hunters?.filter(
          (h: HunterData) => h.id !== hunterId
        ),
      }));
    },
    [setFilters]
  );

  const onUpdateCities = (city: Nullable<AutocompleteValue<CityData>>) => {
    const updatedFilters = updateCities(filters, city);
    setFilters(updatedFilters);
  };

  const handleRemoveCity = useCallback(
    (city: string) => {
      setFilters((prevFilters: IRealEstatesFilters) => ({
        ...prevFilters,
        cities: prevFilters?.cities?.filter((c: CityData) => c.id !== city),
      }));
    },
    [setFilters]
  );

  const huntersChip = useMemo(
    () =>
      filters?.hunters?.map((hunter: HunterData) => (
        <Chip
          label={hunter.name}
          key={hunter.id}
          size="small"
          onDelete={() => handleRemoveHunter(hunter.id)}
        />
      )),
    [filters?.hunters, handleRemoveHunter]
  );

  const citiesChip = useMemo(
    () =>
      filters?.cities?.map((city: { id: string }) => (
        <Chip
          label={city.id}
          key={city.id}
          size="small"
          onDelete={() => handleRemoveCity(city.id)}
        />
      )),
    [filters?.cities, handleRemoveCity]
  );

  const onAddressChange = (
    _place: string,
    rawData: Nullable<RawGooglePlaceSuggestion>
  ) => {
    setFilters({
      ...filters,
      address: rawData?.description ?? "",
      placeId: rawData?.place_id ?? "",
    });
  };

  return (
    <Form>
      <CheckboxGroup
        filters={filters}
        label={translate("realEstate.statusLabel")}
        checkboxes={statusesCheckboxes}
        setFilters={setFilters}
        source="status"
      />

      <CollapsibleSection
        label={translate("shared.hunter")}
        isDefaultExpanded={!!filters.hunters}
      >
        <CollapsibleSection.GroupSpacer>
          <FormGroup>
            <HuntersAutocomplete onSelect={onUpdateHunters} />
            <ChipContainer>{huntersChip}</ChipContainer>
          </FormGroup>
        </CollapsibleSection.GroupSpacer>
      </CollapsibleSection>

      <FiltersRangeGroup
        label={translate("financial.projectAmount")}
        source="projectCost"
        filters={filters}
        setFilters={setFilters}
      />

      <FiltersRangeGroup
        label={translate("financial.grossReturn")}
        source="grossReturn"
        filters={filters}
        setFilters={setFilters}
      />

      <CollapsibleSection
        label={translate("shared.location.address")}
        isDefaultExpanded={!!filters.address}
      >
        <CollapsibleSection.GroupSpacer>
          <FormGroup>
            <GooglePlacesAutocomplete
              defaultValue={filters.address}
              source="address"
              size="small"
              onChange={onAddressChange}
            />
          </FormGroup>
        </CollapsibleSection.GroupSpacer>
      </CollapsibleSection>

      <CollapsibleSection
        label={capitalizeFirstLetter(translate("shared.address.city"))}
        isDefaultExpanded={!!filters.cities}
      >
        <CollapsibleSection.GroupSpacer>
          <FormGroup>
            <CitiesAutocomplete onSelect={onUpdateCities} />
            <ChipContainer>{citiesChip}</ChipContainer>
          </FormGroup>
        </CollapsibleSection.GroupSpacer>
      </CollapsibleSection>

      <CheckboxGroup
        filters={filters}
        label={translate("realEstate.propertyType")}
        checkboxes={typesCheckboxes}
        setFilters={setFilters}
        source="types"
      />

      <CheckboxGroup
        filters={filters}
        label={translate("realEstate.locationType")}
        checkboxes={rentingTypesCheckboxes}
        setFilters={setFilters}
        source="rentingTypes"
      />
    </Form>
  );
};
