import React from "react";

import { Form as RAForm } from "react-admin";
import { yupResolver } from "@hookform/resolvers/yup";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

import { userForm } from "./AddUser.validator";
import { UserFormFields } from "./UserFormFields";

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
`;

const Form = styled(RAForm)`
  box-shadow: none;
`;

interface UserFormProps {
  isEdit?: boolean;
  handleSubmit?: (data: Record<string, unknown>) => Promise<void>;
  cityManagers:
    | {
        label: string;
        value: string;
      }[]
    | undefined;
}

export const UserForm: React.FC<UserFormProps> = ({
  handleSubmit,
  cityManagers,
  isEdit,
}) => {
  return (
    <Form
      onSubmit={handleSubmit}
      id="user-form"
      resolver={yupResolver(userForm)}
    >
      <Container>
        <UserFormFields cityManagers={cityManagers} isEdit={isEdit} />
      </Container>
    </Form>
  );
};
