import crudRestProvider from "ra-data-simple-rest";
import {
  httpClientAuth,
  post,
  put,
} from "utils/http-client-auth/httpClientAuth";
import {
  ArchiveBody,
  ForgotPasswordBody,
  RenewPasswordBody,
  ResetPasswordBody,
  ResetPasswordResponseBody,
} from "types/customMethods.types";

const apiUrl = process.env.REACT_APP_API_URL;
const identityApiUrl = process.env.REACT_APP_IDENTITY_API_URL;

if (!apiUrl) {
  throw Error("REACT_APP_API_URL is not defined");
}

const restMethods = crudRestProvider(apiUrl, httpClientAuth);

const realEstateCustomMethods = {
  archive: (id: string, body: ArchiveBody) =>
    put(`${apiUrl}/real-estates/${id}/action/archive`, {
      body: JSON.stringify(body),
    }),
  askPublish: (id: string): Promise<unknown> =>
    put(`${apiUrl}/real-estates/${id}/action/publish-request`),
  doPublish: (id: string): Promise<unknown> =>
    put(`${apiUrl}/real-estates/${id}/action/publish-validation`),
};
const identityCustomMethods = {
  forgotPassword: (body: ForgotPasswordBody) =>
    post(`${identityApiUrl}/forgot-password`, {
      body: JSON.stringify(body),
    }),
  renewPassword: (body: RenewPasswordBody) =>
    post(`${identityApiUrl}/renew-password`, {
      body: JSON.stringify(body),
    }),
  resetPassword: (body: ResetPasswordBody) =>
    post<ResetPasswordResponseBody>(`${identityApiUrl}/reset-password`, {
      body: JSON.stringify(body),
    }),
};

export const dataProvider = {
  ...restMethods,
  ...realEstateCustomMethods,
  ...identityCustomMethods,
};
