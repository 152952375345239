import { Chip } from "@mui/material";
import { useTranslate } from "react-admin";

import { STATUS_COLORS } from "constants/real-estates";

export const StatusChip: React.FC<{ status: string }> = ({ status }) => {
  const translate = useTranslate();

  const value = status.toLowerCase();

  return (
    <Chip
      label={translate(`realEstate.status.${value}`)}
      color={STATUS_COLORS[value]}
    ></Chip>
  );
};
