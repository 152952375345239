import * as yup from "yup";
import {
  addressSchema,
  archivingReasonSchema,
  propertyKindSchema,
  propertyLangSchema,
  synthesisSchema,
  roomNumberSchema,
  floorSchema,
} from "features/realEstates/validators/realEstate.validator";

// @todo: get schema from helios backend
export const propertyEditForm = yup.object().shape({
  address: addressSchema,
  floor: floorSchema,
  lang: propertyLangSchema,
  roomNumber: roomNumberSchema,
  synthesis: synthesisSchema,
  type: propertyKindSchema,
});
export const propertyArchiveForm = yup.object().shape({
  archivingReason: archivingReasonSchema,
});
export const propertyDuplicateForm = yup.object().shape({
  address: addressSchema,
  lang: propertyLangSchema,
  type: propertyKindSchema,
});
