import { fetchUtils } from "react-admin";

import { authStorage } from "constants/storage";

interface Options extends RequestInit {
  user?: {
    authenticated?: boolean;
    token?: string;
  };
}

interface HttpClientResponse {
  status: number;
  headers: Headers;
  body: string;
  json: unknown;
}

export const httpClientAuth = (
  url: string,
  options: Options = {}
): Promise<HttpClientResponse> => {
  const jwtToken = localStorage.getItem(authStorage.token) ?? "";

  if (jwtToken) {
    options.user = {
      authenticated: true,
      token: `bearer ${jwtToken}`,
    };
  }

  return fetchUtils.fetchJson(url, options);
};

export const post = <T = unknown>(
  url: string,
  options: Options = {}
): Promise<{ data: T }> =>
  httpClientAuth(url, { ...options, method: "POST" }).then(({ json }) => ({
    data: json as T,
  }));

export const put = <T = unknown>(
  url: string,
  options: Options = {}
): Promise<{ data: T }> =>
  httpClientAuth(url, { ...options, method: "PUT" }).then(({ json }) => ({
    data: json as T,
  }));

export const get = <T = unknown>(
  url: string,
  options: Options = {}
): Promise<{ data: T }> =>
  httpClientAuth(url, { ...options, method: "GET" }).then(({ json }) => ({
    data: json as T,
  }));
