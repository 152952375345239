import React from "react";

import { Modal } from "ui/organisms/modal/Modal";
import { Button } from "ui/atoms/button/Button";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { RaRecord, useDelete, useNotify, useTranslate } from "react-admin";

const FooterContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const ButtonsContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: ${(props) => props.theme.spacing(2)};
`;

interface ConfirmDeleteUserModalProps {
  onClose: () => void;
  open: boolean;
  onSuccess?: (id: string) => void;
  id: string;
}

export const ConfirmDeleteUserModal: React.FC<ConfirmDeleteUserModalProps> = ({
  onClose,
  id,
  onSuccess,
  open,
}) => {
  const translate = useTranslate();
  const [deleteOne] = useDelete<RaRecord, Error>(
    "users",
    { id },
    {
      onError: (error) => {
        notify(error?.message || "user.edit.error", {
          type: "error",
        });
      },
      onSuccess: () => {
        onSuccess && onSuccess(id);
        notify("user.form.delete.successToaster", { type: "success" });
      },
    }
  );
  const notify = useNotify();

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={translate("users.confirmDelete")}
      footer={
        <FooterContainer>
          <ButtonsContainer>
            <Button
              data-testid="cancel-button"
              variant="text"
              onClick={onClose}
            >
              {translate("ra.action.cancel")}
            </Button>
            <Button
              variant="text"
              onClick={() => deleteOne()}
              data-testid="confirm-button"
            >
              {translate("ra.action.delete")}
            </Button>
          </ButtonsContainer>
        </FooterContainer>
      }
    ></Modal>
  );
};
