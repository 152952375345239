import { Fieldset } from "ui/molecules/fieldset/Fieldset";
import {
  NullableBooleanInput,
  NumberInput,
  SelectInput,
  useTranslate,
} from "react-admin";
import { FormGroup } from "@mui/material";
import { CheckboxInput } from "components/checkbox-input/CheckboxInput";
import { AccordionForm } from "ui/molecules/accordion-form/AccordionForm";
import { styled } from "@mui/material/styles";

import {
  LOCATION_TYPE_DATA,
  PROPERTY_KIND_DATA,
  RENTAL_SITUATIONS_DATA,
} from "constants/real-estates";

import { RealEstateEditAccordeonFormProps } from "./RealEstateEditAccordeonFormProps";

const NullableInput = styled(NullableBooleanInput)`
  &.RaNullableBooleanInput-input {
    width: 100%;
  }
`;
export const ProjectForm: React.FC<RealEstateEditAccordeonFormProps> = ({
  defaultExpanded,
}) => {
  const translate = useTranslate();

  return (
    <AccordionForm
      defaultExpanded={defaultExpanded}
      title={translate("realEstate.form.project")}
    >
      <Fieldset>
        <SelectInput
          source="type"
          choices={PROPERTY_KIND_DATA}
          label="realEstate.input.propertyKind"
          margin="none"
          variant="outlined"
        />
        <NumberInput
          source="surface"
          label="realEstate.input.surface"
          margin="none"
          variant="outlined"
        />
        <NumberInput
          source="roomNumber"
          label="realEstate.input.roomNumber"
          margin="none"
          variant="outlined"
          step={1}
        />
        <NumberInput
          source="floor"
          label="realEstate.input.floor"
          margin="none"
          variant="outlined"
        />
        <SelectInput
          source="locationType"
          choices={LOCATION_TYPE_DATA}
          label="realEstate.input.locationType"
          margin="none"
          variant="outlined"
        />
        <SelectInput
          source="rentalSituation"
          choices={RENTAL_SITUATIONS_DATA}
          label="realEstate.input.rentalSituation"
          margin="none"
          variant="outlined"
        />
        <NumberInput
          source="totalLots"
          label="realEstate.input.totalLots"
          margin="none"
          variant="outlined"
        />
        <NullableInput
          label="realEstate.input.isFurnished"
          source="isFurnished"
          falseLabel="realEstate.isFurnished.notFurnished"
          trueLabel="realEstate.isFurnished.furnished"
          margin="none"
          variant="outlined"
        />
        <FormGroup row>
          <CheckboxInput
            label="realEstate.input.hasCoOwnership"
            source="hasCoOwnership"
          />
        </FormGroup>
      </Fieldset>
    </AccordionForm>
  );
};
