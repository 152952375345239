import { frFR, esES, enUS } from "langs";

export const getInternalNoteTemplate = (local: string): string => {
  switch (local) {
    case "EN-US":
      return enUS.realEstate.internalNoteTemplate;
    case "FR":
      return frFR.realEstate.internalNoteTemplate;
    case "ES":
      return esES.realEstate.internalNoteTemplate;
    default:
      return enUS.realEstate.internalNoteTemplate;
  }
};
