import { useEffect, useRef, useState } from "react";

import { NotificationPayload, useTranslate } from "react-admin";
import { useNotificationContext } from "ra-core";
import {
  SnackbarKey,
  useNotification,
} from "hooks/use-notification/useNotification";

export const Notifier: React.FC = () => {
  const translate = useTranslate();
  const { notifications, takeNotification } = useNotificationContext();
  const { openError, openSuccess, close } = useNotification();
  const errorRef = useRef<Nullable<SnackbarKey>>(null);
  const [messageInfo, setMessageInfo] = useState<NotificationPayload | void>(
    undefined
  );

  useEffect(() => {
    if (notifications.length && !messageInfo) {
      // Set a new snack when we don't have an active one
      setMessageInfo(takeNotification());
      const notification = takeNotification();

      if (notification) {
        switch (notification.type) {
          case "error":
          case "warning": // yup set validation error messages to warning type...
            errorRef.current = openError({
              message: translate(notification.message),
            });
            break;
          case "success":
            if (errorRef.current) {
              close(errorRef.current);
            }
            openSuccess({ message: translate(notification.message) });
        }
      }
    } else if (notifications.length && messageInfo) {
      // Close an active snack when a new one is added
      if (errorRef.current) {
        close(errorRef.current);
      }
      setMessageInfo(undefined);
    }

    return () => {
      errorRef.current = null;
    };
  }, [
    notifications,
    messageInfo,
    takeNotification,
    openError,
    close,
    openSuccess,
    translate,
  ]);

  return null;
};
