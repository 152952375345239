import { CellType, GridColDef } from "types/mui.types";

export const useUsersColumns = (): GridColDef[] => {
  return [
    {
      field: "firstName",
      headerName: "user.input.firstname",
      width: 150,
    },
    {
      field: "lastName",
      headerName: "user.input.lastname",
      width: 150,
    },
    {
      field: "roles",
      headerName: "user.input.role",
      width: 200,
    },
    {
      field: "email",
      headerName: "user.input.email",
      width: 250,
    },
    {
      cellType: CellType.DATE,
      field: "createdAt",
      headerName: "user.header.createdAt",
      width: 150,
    },
  ] as GridColDef[];
};
