import { useContext, useEffect, useMemo } from "react";

import { useMediaQuery } from "@mui/material";
import { RealEstateAction } from "types/realEstate.types";
import { Theme } from "@mui/system";
import { useCreateContext, useStore } from "react-admin";
import { useFormContext } from "react-hook-form";

import {
  ActionContext,
  ActionContextProps,
} from "../context/ActionContextProvider";
import { ActionMutationReturn } from "./useArchiveAction";

interface RealEstateActions {
  dropdownActions: RealEstateAction[];
  archive: ActionMutationReturn<unknown, unknown, unknown, unknown>;
  askPublish: ActionMutationReturn<unknown, unknown, void, unknown>;
  doPublish: ActionMutationReturn<unknown, unknown, void, unknown>;
}

/**
 * Provider all actions from ActionContextProvider.
 * @param formContext the curent form context. Needed in order to dispatch server errors back to the form context.
 */
export const useRealEstateActions = (
  formContext?: ReturnType<typeof useFormContext>
): RealEstateActions => {
  const { archive, askPublish, doPublish, setFormContext } =
    useContext<ActionContextProps>(ActionContext);

  const { saving } = useCreateContext();

  /** Set the form context to the parent ActionContext provider */
  useEffect(() => {
    if (formContext) {
      setFormContext(formContext);
    }
  }, [formContext, setFormContext]);

  const [, displayArchiveModal] = useStore(
    "realEstate.edit.show_archive_modal",
    false
  );
  const [, displayDuplicateModal] = useStore(
    "realEstate.edit.show_add_modal",
    false
  );
  const isSmall = useMediaQuery(
    (theme: Theme) => `(max-width:${theme.breakpoints.values.lg}px)`
  );

  const dropdownActions = useMemo(() => {
    const actionList: RealEstateAction[] = [
      {
        callback: askPublish.mutate,
        loading: askPublish.isLoading,
        name: "realEstate.action.askPublish.label",
        show: !!askPublish.isAllowed && isSmall,
        slug: "askPublish",
      },
      {
        callback: doPublish.mutate,
        loading: doPublish.isLoading,
        name: "realEstate.action.doPublish.label",
        show: !!doPublish.isAllowed && isSmall,
        slug: "doPublish",
      },
      {
        callback: () => displayArchiveModal(true),
        loading: archive.isLoading,
        name: "realEstate.action.archive.label",
        show: !!archive.isAllowed,
        slug: "archive",
      },
      {
        callback: () => displayDuplicateModal(true),
        loading: !!saving,
        name: "realEstate.action.duplicate.label",
        show: true,
        slug: "duplicate",
      },
    ];
    return actionList.filter((action: RealEstateAction) => action.show);
  }, [
    askPublish,
    isSmall,
    doPublish.mutate,
    doPublish.isLoading,
    doPublish.isAllowed,
    archive.isLoading,
    archive.isAllowed,
    saving,
    displayArchiveModal,
    displayDuplicateModal,
  ]);

  return { archive, askPublish, doPublish, dropdownActions };
};
