import React from "react";

import { Pagination } from "@mui/material";

export interface DataGridPaginationProps {
  currentPage: number;
  onChangePage: (event: React.ChangeEvent<unknown>, nextPage: number) => void;
  totalRecords: number;
  perPage: number;
}

export const DataGridPagination: React.FC<DataGridPaginationProps> = (
  props
) => {
  const { currentPage, onChangePage, totalRecords, perPage } = props;
  return (
    <Pagination
      data-testid="data-grid-pagination"
      count={totalRecords ? Math.ceil(totalRecords / perPage) : 0}
      page={currentPage}
      size="small"
      onChange={onChangePage}
      siblingCount={2}
    />
  );
};
