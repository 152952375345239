import { PropsWithChildren } from "react";

import { Box, Grid, Typography, useTheme } from "@mui/material";
import { Card } from "ui/atoms/card/Card";
import { CardContent } from "ui/atoms/card-content/CardContent";

type AuthCardProps = PropsWithChildren<{
  title: string;
  subtitle?: string;
}>;

export const AuthCard: React.FC<AuthCardProps> = ({
  title,
  subtitle,
  children,
}) => {
  const theme = useTheme();
  return (
    <Grid
      width="100%"
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Card elevation={5}>
        <CardContent>
          <Box mb={theme.spacing(2)}>
            <Typography variant="h5">{title}</Typography>
            {!!subtitle && (
              <Typography variant="caption" gutterBottom>
                {subtitle}
              </Typography>
            )}
          </Box>
          {children}
        </CardContent>
      </Card>
    </Grid>
  );
};
