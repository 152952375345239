import { Box, styled, Typography } from "@mui/material";

const Container = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  gap: 8px;
  width: max-content;

  & .metricInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
    padding: 0;
  }
`;

interface MetricBlockProps {
  icon: JSX.Element;
  value: string | JSX.Element;
  text: string;
}

export const MetricBlock: React.FC<MetricBlockProps> = ({
  icon,
  value,
  text,
}) => {
  return (
    <Container>
      {icon}
      <Container component="div" className="metricInfo">
        <Typography variant="body2">{value}</Typography>
        <Typography variant="caption">{text}</Typography>
      </Container>
    </Container>
  );
};
