import jwtDecode, { JwtPayload } from "jwt-decode";

import { authStorage } from "constants/storage";

export const checkAuth = (): Promise<void> => {
  // Check if token in local storage
  const token = localStorage.getItem(authStorage.token);
  if (!token) {
    return Promise.reject();
  }
  // Check if token is expired
  const { exp } = jwtDecode<JwtPayload>(token);

  const nowSeconds = new Date().getTime() / 1000;
  if (!exp || nowSeconds > exp) {
    return Promise.reject({ message: "checkAuth.error.expiredToken" });
  }

  return Promise.resolve();
};
