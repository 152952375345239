import * as yup from "yup";

import {
  ARCHIVING_REASONS_DATA,
  LANGS_DATA,
  PROPERTY_KIND_DATA,
} from "constants/real-estates";

export interface IdName {
  id: string;
  name: string;
}

export const addressSchema = yup.object().shape({
  street: yup.string().required(),
});

export const propertyLangSchema = yup
  .string()
  .oneOf(LANGS_DATA.map((lang: IdName) => lang.id))
  .required("realEstate.form.langRequired");

export const propertyKindSchema = yup
  .string()
  .oneOf(PROPERTY_KIND_DATA.map((propertyKind: IdName) => propertyKind.id))
  .required("realEstate.form.propertyKind.notInList");

export const archivingReasonSchema = yup
  .string()
  .oneOf(
    ARCHIVING_REASONS_DATA.map((archivingReason: IdName) => archivingReason.id)
  )
  .required("realEstate.form.reasonFieldRequired");

export const roomNumberSchema = yup
  .number()
  .integer("realEstate.form.roomNumberInteger")
  .nullable(true)
  .transform((_, val) => (val === Number(val) ? val : null));

export const floorSchema = yup
  .number()
  .integer("realEstate.form.floorInteger")
  .nullable(true)
  .transform((_, val) => (val === Number(val) ? val : null));

export const synthesisSchema = yup
  .array()
  .of(
    yup.object().shape({
      description: yup
        .string()
        .max(200, "realEstate.synthesis.error.descriptionMaxLength")
        .required("realEstate.synthesis.error.descriptionRequired")
        .nullable(),
      theme: yup
        .string()
        .required("realEstate.synthesis.error.themeRequired")
        .nullable(),
    })
  )
  .max(4, "realEstate.synthesis.error.maxLength");
