import { useState, useMemo, useCallback } from "react";

import {
  useTranslate,
  useListController,
  usePermissions,
  ListBase,
} from "react-admin";
import { GridCellParams } from "@mui/x-data-grid";
import { DataTable } from "components/data-table/DataTable";
import { getColumns } from "utils/columns/getColumns.utils";
import { FilteredListLayout } from "ui/layouts/filtered-list-layout/FilteredListLayout";

import { SEARCH_DEBOUNCE_TIME } from "constants/common";

import { useUsersColumns } from "./hooks/useGetUsersColumns";
import { UsersFilters } from "./components/filters/UsersFilters";
import { AddUserModalForm } from "./components/modals/AddUserModalForm";
import { EditUserModalForm } from "./components/modals/EditUserModalForm";
import { ConfirmDeleteUserModal } from "./components/modals/confirmDeleteUserModal";

const PER_PAGE = 20;

export const UsersList: React.FC = () => {
  const { permissions } = usePermissions();
  const [openModalCreate, setOpenModalCreate] = useState(false);
  const [openModalConfirmDelete, setOpenModalConfirmDelete] = useState(false);
  const [editId, setEditId] = useState<string | undefined>(undefined);
  const columnsList = useUsersColumns();
  const translate = useTranslate();
  const {
    filterValues,
    setFilters,
    data: users,
    total,
  } = useListController({
    debounce: SEARCH_DEBOUNCE_TIME,
    perPage: PER_PAGE,
  });

  const allowedUsersManagers = ["admin", "dev", "hunterManager"];
  const canSeeUsers =
    Array.isArray(permissions) &&
    allowedUsersManagers.some((right) => permissions.includes(right));

  const columns = useMemo(
    () => (users ? getColumns(columnsList, translate) : []),
    [users, translate, columnsList]
  );

  const rows = useMemo(() => users ?? [], [users]);

  const openModal = useCallback(() => {
    setOpenModalCreate(true);
  }, []);

  const closeModal = useCallback(() => {
    setOpenModalCreate(false);
    setEditId(undefined);
  }, []);

  const openModalConfirm = useCallback(() => {
    setOpenModalConfirmDelete(true);
  }, []);

  const closeModalConfirmDelete = useCallback(() => {
    setOpenModalConfirmDelete(false);
    setEditId(undefined);
  }, []);

  const cancelUserDelete = useCallback(() => {
    setOpenModalConfirmDelete(false);
  }, []);

  const handleCellClick = ({ id }: GridCellParams) => {
    setEditId(id as string);
  };

  const table = (
    <DataTable onCellClick={handleCellClick} columns={columns} rows={rows} />
  );

  return canSeeUsers ? (
    <>
      <ListBase perPage={PER_PAGE}>
        <FilteredListLayout
          main={table}
          filters={<UsersFilters />}
          title={translate("user.list", { count: total ?? 0 })}
          addTitle={translate("users.add")}
          onAddClick={openModal}
          searchPlaceholder={translate("user.search.placeholder")}
          setFilters={setFilters}
          filterValues={filterValues}
        />
      </ListBase>
      <AddUserModalForm
        open={openModalCreate}
        onClose={closeModal}
        onSuccess={closeModal}
      />
      {!!editId && (
        <>
          <EditUserModalForm
            id={editId}
            onClose={closeModal}
            openModalConfirmDelete={openModalConfirm}
          />
          <ConfirmDeleteUserModal
            onClose={cancelUserDelete}
            open={openModalConfirmDelete}
            id={editId}
            onSuccess={closeModalConfirmDelete}
          />
        </>
      )}
    </>
  ) : null;
};
