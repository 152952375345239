import {
  useDataProvider,
  useEditContext,
  useGetIdentity,
  usePermissions,
  HttpError,
  useRefresh,
  useNotify,
} from "react-admin";
import { useFormContext } from "react-hook-form";
import { useMutation } from "react-query";
import {
  PublishValidationError,
  PublishValidationException,
} from "types/realEstate.types";

import { ActionMutationReturn } from "./useArchiveAction";

interface PublishActions {
  doPublish: ActionMutationReturn<unknown, unknown, void, unknown>;
  askPublish: ActionMutationReturn<unknown, unknown, void, unknown>;
}

export const usePublishActions = (
  formContext?: ReturnType<typeof useFormContext>
): PublishActions => {
  const refresh = useRefresh();
  const notify = useNotify();
  const { record, save } = useEditContext();
  const { identity } = useGetIdentity();
  const { permissions } = usePermissions();
  const dataProvider = useDataProvider();

  const isOwnRealEstate = record?.hunter._id === identity?.id;

  const handlePublishActionErrors = ({
    exception,
  }: {
    exception: PublishValidationException;
  }) => {
    const ignoreFields: string[] = [
      "financialModel.projectAmount",
      "financialModel.annualTotalIncome",
      "financialModel.annualTotalExpenses",
      "financialModel.annualLoanPayments",
      "financialModel.annualTotalEarnings",
      "financialModel.netReturn",
      "financialModel.grossReturn",
    ];
    if (exception?.inner && Array.isArray(exception.inner)) {
      exception.inner.forEach((error, index) => {
        if (!ignoreFields.includes(error.path)) {
          const key = `realEstate.publishValidation.fields.${error.path}`;
          formContext?.setValue(
            error.path,
            formContext?.getValues(error.path),
            {
              shouldTouch: true,
            }
          );
          formContext?.setError(
            error.path,
            { message: key },
            { shouldFocus: index === 0 }
          );
        }
      });
    }
  };

  const askPublish = useMutation<unknown, unknown, void, unknown>(
    ["askPublish", record?.id, dataProvider],
    () => {
      return new Promise((resolve, reject) => {
        save &&
          save(formContext?.getValues() as Record<string, unknown>, {
            onError: reject,
            onSuccess: resolve,
          });
      }).then(() => {
        return dataProvider
          ?.askPublish(record?.id)
          .then((response: { data: unknown }) => {
            if (response) {
              refresh();
              notify("realEstate.action.askPublish.success", {
                type: "success",
              });
            }
          })
          .catch((err: HttpError) => {
            console.log(err.body);
            let message: string;
            switch (err.status) {
              case 400:
                message = `realEstate.action.askPublish.invalidForm`;
                handlePublishActionErrors((err as PublishValidationError).body);
                break;
              case 403:
                message = `realEstate.action.askPublish.${err.message}`;
                break;
              default:
                message = "ra.notification.http_error";
            }

            notify(message, {
              type: "error",
            });
            throw err;
          });
      });
    }
  );
  const doPublish = useMutation<unknown, unknown, void, unknown>(
    ["doPublish", record?.id, dataProvider],
    () => {
      return dataProvider
        ?.doPublish(record?.id)
        .then((response: { data: unknown }) => {
          if (response) {
            refresh();
            notify("realEstate.action.doPublish.success", {
              type: "success",
            });
          }
        })
        .catch((err: HttpError) => {
          let message: string;
          switch (err.status) {
            case 400:
              throw err;
            case 403:
              message = `realEstate.action.doPublish.${err.message}`;
              break;
            default:
              message = "ra.notification.http_error";
          }

          notify(message, {
            type: "error",
          });
        });
    }
  );

  const canAskPublish =
    permissions &&
    permissions.includes("hunter") &&
    isOwnRealEstate &&
    record?.status === "DRAFT";

  const canDoPublish =
    permissions &&
    permissions.includes("hunterManager") &&
    !canAskPublish &&
    record?.status === "IN_REVIEW";

  return {
    askPublish: { ...askPublish, isAllowed: canAskPublish },
    doPublish: { ...doPublish, isAllowed: canDoPublish },
  };
};
