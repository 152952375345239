import { useMemo } from "react";

import {
  HttpError,
  useDataProvider,
  useEditContext,
  useNotify,
  useRefresh,
} from "react-admin";
import { useMutation, UseMutationResult } from "react-query";

export type ActionMutationReturn<TData, TError, TVariables, TContext> =
  UseMutationResult<TData, TError, TVariables, TContext> & {
    isAllowed?: boolean;
  };

export const useArchiveAction = (): ActionMutationReturn<
  unknown,
  unknown,
  unknown,
  unknown
> => {
  const { record } = useEditContext();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const isAllowed = useMemo(
    () => !["ARCHIVED", "TRANSACTION", "SOLD"].includes(record?.status),
    [record]
  );

  const mutation = useMutation(
    ["archive", record?.id, dataProvider],
    (data: unknown) => {
      return dataProvider
        ?.archive(record?.id, data)
        .then((response: unknown) => {
          if (response) {
            refresh();
            notify("realEstate.action.archive.success", {
              type: "success",
            });
          }
        })
        .catch((err: HttpError) => {
          const message =
            err.status === 403
              ? `realEstate.action.${err.message}`
              : "ra.notification.http_error";

          notify(message, {
            type: "error",
          });
        });
    }
  );
  return { ...mutation, isAllowed };
};
