import { Fieldset } from "ui/molecules/fieldset/Fieldset";
import { SelectInput, TextInput, useTranslate } from "react-admin";
import { ImageField } from "ui/organisms/image-field/ImageField";
import { AccordionForm } from "ui/molecules/accordion-form/AccordionForm";
import { TextArea } from "ui/atoms/textarea/TextArea";

import {
  DPE_DATA,
  ESTIMATED_WORKS_DATA,
  GES_DATA,
} from "constants/real-estates";

import { RealEstateEditAccordeonFormProps } from "./RealEstateEditAccordeonFormProps";
export const RenovationForm: React.FC<RealEstateEditAccordeonFormProps> = ({
  defaultExpanded,
}) => {
  const translate = useTranslate();

  return (
    <AccordionForm
      defaultExpanded={defaultExpanded}
      title={translate("realEstate.form.renovation")}
    >
      <Fieldset>
        <SelectInput
          margin="none"
          variant="outlined"
          label="realEstate.dpe"
          choices={DPE_DATA}
          translateChoice={false}
          source="dpe"
        />
        <SelectInput
          margin="none"
          variant="outlined"
          label="realEstate.ges"
          choices={GES_DATA}
          translateChoice={false}
          source="ges"
        />
        <TextInput
          margin="none"
          variant="outlined"
          label="realEstate.input.renovationToolUrl"
          source="renovationToolUrl"
        />
        <SelectInput
          source="estimatedWorks"
          choices={ESTIMATED_WORKS_DATA}
          label="realEstate.input.estimatedWorks"
          margin="none"
          variant="outlined"
        />
        <TextArea
          fullWidth
          label="realEstate.input.renovationDescription"
          source="renovation.description"
        />
      </Fieldset>
      <Fieldset title={translate("realEstate.fieldset.imagesPlans")} />
      <ImageField source="plans.images" />
    </AccordionForm>
  );
};
