import { useTranslate } from "react-admin";
import { CellType, GridColDef } from "types/mui.types";
import {
  formatColumnValueHunter,
  formatColumnValueType,
  formatColumnValueTypeLocation,
} from "features/realEstates/list/utils/columnsValueFormatters";
import { renderCellStatus } from "features/realEstates/list/utils/columnsRenderCell";

export const useGetRealEstatesColumns = (): GridColDef[] => {
  const translate = useTranslate();

  return [
    {
      field: "status",
      headerName: "realEstate.statusLabel",
      renderCell: renderCellStatus(translate),
      width: 130,
    },
    {
      field: "hunter.lastName",
      headerName: "shared.hunter",
      valueFormatter: formatColumnValueHunter,
      width: 200,
    },
    {
      field: "type",
      headerName: "realEstate.propertyType",
      valueFormatter: formatColumnValueType(translate),
      width: 150,
    },
    {
      field: "address.city",
      headerName: "shared.address.city",
      width: 130,
    },
    {
      field: "address.street",
      headerName: "shared.address.street",
      width: 200,
    },
    {
      cellType: CellType.PRICE,
      field: "financialModel.projectAmount",
      headerName: "financial.totalBudget",
      width: 130,
    },
    {
      cellType: CellType.PERCENTAGE,
      field: "financialModel.grossReturn",
      headerName: "financial.grossReturn",
      width: 130,
    },
    {
      cellType: CellType.PERCENTAGE,
      field: "financialModel.netReturn",
      headerName: "financial.netReturn",
      width: 130,
    },
    {
      cellType: CellType.PRICE,
      field: "financialModel.netSeller",
      headerName: "financial.salesPrice",
      width: 130,
    },
    {
      field: "surface",
      headerName: "realEstate.surface",
      width: 130,
    },
    {
      field: "totalLots",
      headerName: "realEstate.totalLots",
      width: 130,
    },
    {
      field: "roomNumber",
      headerName: "realEstate.roomNumber",
      width: 130,
    },
    {
      field: "dpe",
      headerName: "realEstate.dpe",
      width: 130,
    },
    {
      cellType: CellType.DATE,
      field: "createdAt",
      headerName: "realEstate.createdAt",
      width: 200,
    },
    {
      cellType: CellType.DATE,
      field: "updatedAt",
      headerName: "shared.publicationDate",
      width: 200,
    },
    //no need
    {
      field: "financialModel.evaluationRate",
      headerName: "realEstate.evaluationRate",
      width: 130,
    },
    {
      field: "locationType",
      headerName: "realEstate.locationType",
      valueFormatter: formatColumnValueTypeLocation(translate),
      width: 130,
    },
    {
      field: "_id",
      headerName: "realEstate.id",
      width: 210,
    },
  ] as GridColDef[];
};
