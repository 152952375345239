import { ButtonProps } from "@mui/material";
import { useSaveContext } from "react-admin";
import { useFormContext, useFormState } from "react-hook-form";
import { LoadingButton } from "ui/molecules/loading-button/LoadingButton";

interface SaveButtonProps extends ButtonProps {
  label: string;
  name?: string;
  alwaysEnabled?: boolean;
}

export const SaveButton: React.FC<SaveButtonProps> = ({
  alwaysEnabled,
  label,
  name,
  variant = "contained",
  ...rest
}) => {
  const _formContext = useFormContext();
  const { saving } = useSaveContext();
  const formState = useFormState({ name });
  const { isDirty, isValidating, isSubmitting } = formState;

  const disabled =
    !alwaysEnabled && (!isDirty || isValidating || saving || isSubmitting);

  return (
    <LoadingButton
      variant={variant}
      disabled={disabled}
      loading={saving}
      type="submit"
      {...rest}
    >
      {label}
    </LoadingButton>
  );
};
