import { authStorage } from "constants/storage";

const identityApiUrl = process.env.REACT_APP_IDENTITY_API_URL;

export const login = ({
  email,
  password,
}: {
  email: string;
  password: string;
}): Promise<void> => {
  const request = new Request(`${identityApiUrl}/authenticate`, {
    body: JSON.stringify({ email, password, strategy: "local" }),
    headers: new Headers({
      "Content-Type": "application/json",
      accept: "application/json",
    }),
    method: "POST",
  });
  return fetch(request)
    .then((response) => {
      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then((auth) => {
      localStorage.setItem(authStorage.token, auth.access_token);
    })
    .catch((error) => {
      throw new Error(error?.statusText || "Network error");
    });
};
