import { useCallback, useEffect, useMemo, useState } from "react";

import { isArray } from "lodash";
import { FileDropZone } from "ui/molecules/file-drop-zone/FileDropZone";
import { ImageCard } from "ui/molecules/image-card/ImageCard";
import { Box, styled } from "@mui/material";
import { useInput, useTranslate, InputHelperText } from "react-admin";
import { FileRejection } from "react-dropzone";
import { useNotification } from "hooks/use-notification/useNotification";
import FormHelperText from "@mui/material/FormHelperText";

import { getImgExt } from "constants/images";

import {
  getKey,
  addFiles,
  deleteFile,
  fileToImage,
  formatErrorMessage,
  fileToBase64,
} from "./utils/imageField.formater.utils";

const Container = styled("div")``;

const FileContainer = styled(Box)`
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr;

  @media (min-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

type ImageFieldType = {
  maxFiles?: number;
  source: string;
};

export const ImageField: React.FC<ImageFieldType> = ({ maxFiles, source }) => {
  const [files, setFiles] = useState<File[]>([]);

  const input = useInput({ source });
  const translate = useTranslate();
  const { openError } = useNotification();

  const {
    field: { onChange, value },
    fieldState,
  } = input;

  const { error, invalid } = fieldState;

  useEffect(() => {
    if (!value) {
      return;
    }

    const file = isArray(value) ? value : [value];
    setFiles(file);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setValue = useCallback(
    async (values: File[]) => {
      const dataValues = await Promise.all(
        values.map((file: File) =>
          file instanceof File ? fileToBase64(file) : file
        )
      );
      setFiles(values);
      onChange(maxFiles === 1 ? dataValues[0] : dataValues);
    },
    [maxFiles, onChange]
  );

  const onAddFiles = useCallback(
    (acceptedFiles: File[]) => {
      const newFileList = addFiles(files, acceptedFiles, maxFiles);
      setValue(newFileList);
    },
    [files, setValue, maxFiles]
  );

  const onRejectedFiles = useCallback(
    (rejectedFile: FileRejection[]) => {
      const message = formatErrorMessage(rejectedFile, translate);
      message && openError({ message });
    },
    [translate, openError]
  );

  const onDeleteFile = useCallback(
    (fileIdentifier: string) => {
      const filteredFiles = deleteFile(files, fileIdentifier);
      setValue(filteredFiles);
    },
    [files, setValue]
  );

  const filesCards = useMemo(() => {
    return files.map((file: File, ind) => {
      if (file === null) {
        return null;
      }

      const blob = fileToImage(file) || "";
      const key = getKey(file, ind);
      return (
        <ImageCard
          key={key}
          src={blob}
          id={key}
          onDeleteClick={onDeleteFile}
          data-testid="imageCard"
        />
      );
    });
  }, [files, onDeleteFile]);

  const showDropZone = useMemo(
    () => (maxFiles ? files.length < maxFiles : true),
    [files, maxFiles]
  );

  const accept = {
    "image/*": getImgExt(),
  };

  return (
    <Container>
      <FileContainer>
        {filesCards}
        {!!showDropZone && (
          <FileDropZone
            onAddFiles={onAddFiles}
            onRejectedFiles={onRejectedFiles}
            source={source}
            accept={accept}
          />
        )}
      </FileContainer>
      <FormHelperText error={true}>
        <InputHelperText touched={invalid} error={error?.message} />
      </FormHelperText>
    </Container>
  );
};
