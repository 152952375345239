// TODO: update any
/* eslint-disable @typescript-eslint/no-explicit-any */
import { transformForDisplay } from "features/realEstates/utils/payloadTransforms";
import isArray from "lodash/isArray";
import { formatFullName } from "utils/strings/strings.utils";

export const formatRealEstateList = (realEstateList: any = []): any[] => {
  return isArray(realEstateList)
    ? realEstateList
        .map(transformForDisplay)
        .map(({ address, financialModel, hunter, ...rest }: any) => ({
          ...rest,
          "address.city": address?.city,
          "address.street": address?.street,
          "financialModel.evaluationRate": financialModel?.evaluationRate,
          "financialModel.grossReturn": financialModel?.grossReturn,
          "financialModel.netReturn": financialModel?.netReturn,
          "financialModel.netSeller": financialModel?.netSeller,
          "financialModel.projectAmount": financialModel?.projectAmount,
          "hunter.lastName": {
            fullName: formatFullName(
              hunter?.firstName || "",
              hunter?.lastName || ""
            ),
            lastName: hunter?.lastName,
          },
        }))
    : [];
};
