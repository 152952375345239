import { createGlobalStyle } from "styled-components";

export const AppCss = createGlobalStyle`
  body {
    background-color: #fafafa;
  }

  @keyframes drawerSlideRightIn {
    100% {
      transform: translateX(0%);
    }
  }

  @keyframes drawerSlideRightOut {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(+100%);
    }
  }
`;
