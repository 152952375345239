import jwtDecode from "jwt-decode";
import { UserIdentity } from "react-admin";

import { authStorage } from "constants/storage";

export const getPermissions = (): Promise<void> => {
  const token = localStorage.getItem(authStorage.token) ?? "";
  if (!token) {
    return Promise.reject();
  }

  const identity = jwtDecode(token) as UserIdentity;
  return identity.roles ? Promise.resolve(identity.roles) : Promise.reject();
};
