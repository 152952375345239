import React, { FunctionComponent, useCallback } from "react";

import {
  email,
  Form,
  required,
  SaveButton,
  TextInput,
  useDataProvider,
  useTranslate,
} from "react-admin";
import { createSearchParams, useNavigate } from "react-router-dom";
import {
  ForgotPasswordBody,
  ResetPasswordOrigin,
} from "types/customMethods.types";
import { AuthCard } from "ui/organisms/auth-card/AuthCard";
import { FieldValues } from "react-hook-form";
import { Button } from "ui/atoms/button/Button";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { useLocaleState } from "ra-core";

const ButtonBox = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 4px;
`;
export const StepEmail: FunctionComponent = () => {
  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const navigate = useNavigate();
  const [locale] = useLocaleState();

  const handleSubmit = useCallback(
    async (data: FieldValues) => {
      try {
        await dataProvider.forgotPassword({
          email: data.email,
          language: locale.slice(0, 2).toLowerCase(),
          origin: ResetPasswordOrigin.HeliosUser,
        } as ForgotPasswordBody);
      } catch ({ status }) {
        if (status === 429) {
          return {
            email: translate("forgotPassword.stepEmail.tooManyRequest"),
          };
        }
        if (status === 500) {
          return { email: translate("forgotPassword.genericError") };
        }
      }
      navigate({
        pathname: "/reset-password/code",
        search: createSearchParams({
          email: data.email,
        }).toString(),
      });
    },
    [dataProvider, navigate, locale, translate]
  );

  return (
    <AuthCard
      title={translate("forgotPassword.stepEmail.title")}
      subtitle={translate("forgotPassword.stepEmail.subtitle")}
    >
      <Form onSubmit={handleSubmit}>
        <TextInput
          label="forgotPassword.stepEmail.emailLabel"
          variant="outlined"
          type="email"
          source="email"
          validate={[required(), email()]}
          size="medium"
          margin="none"
          fullWidth
          inputProps={{
            role: "input",
          }}
          data-testid="reset-password-email"
        />
        <ButtonBox>
          <SaveButton
            size="large"
            disabled={false}
            variant="contained"
            type="submit"
            fullWidth
            label="submit"
            icon={<></>}
            data-testid="forgot-password-email-submit"
          >
            {translate("forgotPassword.stepEmail.submit")}
          </SaveButton>
          <Button
            size="large"
            variant="outlined"
            data-testid="forgot-password-cancel-button"
            fullWidth
            onClick={() => navigate("/login")}
          >
            {translate("forgotPassword.stepEmail.cancel")}
          </Button>
        </ButtonBox>
      </Form>
    </AuthCard>
  );
};
