import { Fieldset } from "ui/molecules/fieldset/Fieldset";
import { TextInput, useEditContext, useInput, useTranslate } from "react-admin";
import { AccordionForm } from "ui/molecules/accordion-form/AccordionForm";
import { GooglePlacesAutocomplete } from "components/google-places-autocomplete/GooglePlacesAutocomplete";
import { getGeocode } from "use-places-autocomplete";
import { getAddressComponents } from "utils/google-maps/googleMaps.utils";
import { formatFullStreet } from "utils/form/form.utils";

import { RealEstateEditAccordeonFormProps } from "./RealEstateEditAccordeonFormProps";

export const LocalizationForm: React.FC<RealEstateEditAccordeonFormProps> = ({
  defaultExpanded,
}) => {
  const translate = useTranslate();
  const { record } = useEditContext();

  const fieldStreet = useInput({ source: "address.street" });
  const fieldCity = useInput({ source: "address.city" });
  const fieldRegionName = useInput({ source: "address.regionName" });
  const fieldCountry = useInput({ source: "address.country" });
  const fieldCountryCode = useInput({ source: "address.countryCode" });
  const fieldPostalCode = useInput({ source: "address.postalCode" });

  const handleOnSelect = async (street: string) => {
    if (!street) {
      return;
    }

    const res = await getGeocode({ address: street });

    const [result] = res;
    if (!res) {
      return;
    }

    const formattedAddressComponents = getAddressComponents(result);

    fieldStreet.field.onChange(
      `${
        (!!formattedAddressComponents.streetNumber &&
          formattedAddressComponents.streetNumber + ", ") ||
        ""
      }${formattedAddressComponents.street}`
    );
    fieldCity.field.onChange(formattedAddressComponents.city);
    fieldRegionName.field.onChange(formattedAddressComponents.regionName);
    fieldCountry.field.onChange(formattedAddressComponents.country);
    fieldCountryCode.field.onChange(formattedAddressComponents.countryCode);
    fieldPostalCode.field.onChange(formattedAddressComponents.postalCode);
  };

  return (
    <AccordionForm
      defaultExpanded={defaultExpanded}
      title={translate("realEstate.form.localization")}
    >
      <Fieldset>
        <GooglePlacesAutocomplete
          fullWidth
          onChange={handleOnSelect}
          source="geocode"
          defaultValue={formatFullStreet(record.address)}
        />
        <TextInput
          margin="none"
          variant="outlined"
          disabled
          label="realEstate.input.street"
          source="address.street"
        />
        <TextInput
          margin="none"
          variant="outlined"
          label="realEstate.input.complement"
          source="address.complement"
        />
        <TextInput
          margin="none"
          variant="outlined"
          label="realEstate.input.buildingCode"
          source="address.buildingCode"
        />
        <TextInput
          margin="none"
          variant="outlined"
          disabled
          label="realEstate.input.postalCode"
          source="address.postalCode"
        />
        <TextInput
          margin="none"
          variant="outlined"
          disabled
          label="realEstate.input.city"
          source="address.city"
        />
        <TextInput
          margin="none"
          variant="outlined"
          disabled
          label="realEstate.input.regionName"
          source="address.regionName"
        />
        <TextInput
          margin="none"
          variant="outlined"
          disabled
          label="realEstate.input.country"
          source="address.country"
        />
        <TextInput
          margin="none"
          variant="outlined"
          disabled
          label="realEstate.input.countryCode"
          source="address.countryCode"
        />
      </Fieldset>
    </AccordionForm>
  );
};
