import React, { useCallback } from "react";

import { Modal } from "ui/organisms/modal/Modal";
import { Form, useTranslate } from "react-admin";
import { FieldValues, Resolver } from "react-hook-form";
import { Box, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { LoadingButton } from "ui/molecules/loading-button/LoadingButton";

const FooterContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const ButtonsContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: ${(props) => props.theme.spacing(2)};
`;

interface ActionModalProps {
  slug: string;
  isOpen: boolean;
  onClose: () => void;
  title: string;
  saveLabel: string;
  children: JSX.Element | JSX.Element[];
  resolver?: Resolver<FieldValues, unknown>;
  callback: (data: FieldValues) => void;
}

export const ActionModal: React.FC<ActionModalProps> = ({
  slug,
  isOpen,
  onClose,
  callback,
  title,
  saveLabel,
  resolver,
  children,
}) => {
  const translate = useTranslate();

  const handleOnClose = useCallback(() => {
    onClose && onClose();
  }, [onClose]);

  const handleOnSave = useCallback(
    (data: FieldValues) => {
      callback && callback(data);
    },
    [callback]
  );

  const ModalFooter = (
    <FooterContainer>
      <ButtonsContainer>
        <Button variant="text" onClick={handleOnClose}>
          {translate("ra.action.cancel")}
        </Button>
        <LoadingButton type="submit" variant="text" form={slug}>
          {saveLabel}
        </LoadingButton>
      </ButtonsContainer>
    </FooterContainer>
  );

  return (
    <Form onSubmit={handleOnSave} id={slug} resolver={resolver}>
      <Modal
        title={title}
        open={isOpen}
        onClose={handleOnClose}
        footer={ModalFooter}
      >
        {children}
      </Modal>
    </Form>
  );
};
