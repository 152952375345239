import { NumberInputProps, NumberInput } from "react-admin";
import { InputAdornment } from "@mui/material";
import PercentIcon from "@mui/icons-material/Percent";

export const PercentInput: React.FC<NumberInputProps> = (props) => (
  <NumberInput
    {...props}
    step={0.1}
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <PercentIcon />
        </InputAdornment>
      ),
    }}
  />
);
