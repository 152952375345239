import { Fieldset } from "ui/molecules/fieldset/Fieldset";
import { useTranslate } from "react-admin";
import { AccordionForm } from "ui/molecules/accordion-form/AccordionForm";
import { TextArea } from "ui/atoms/textarea/TextArea";

import { RealEstateEditAccordeonFormProps } from "./RealEstateEditAccordeonFormProps";
import { SynthesisInput } from "../synthesis-input/SynthesisInput";

export const SynthesisForm: React.FC<RealEstateEditAccordeonFormProps> = ({
  defaultExpanded,
}) => {
  const translate = useTranslate();

  return (
    <AccordionForm
      defaultExpanded={defaultExpanded}
      title={translate("realEstate.form.synthesis")}
    >
      <TextArea
        fullWidth
        label="realEstate.input.description"
        source="description"
      />
      <Fieldset title={translate("realEstate.fieldset.synthesis")}>
        <SynthesisInput />
      </Fieldset>
    </AccordionForm>
  );
};
