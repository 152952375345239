import { useMemo } from "react";

import {
  useRedirect,
  useTranslate,
  useStore,
  useListController,
  ListBase,
} from "react-admin";
import { DataTable } from "components/data-table/DataTable";
import { getColumns } from "utils/columns/getColumns.utils";
import { FilteredListLayout } from "ui/layouts/filtered-list-layout/FilteredListLayout";
import { GridCellParams } from "@mui/x-data-grid";

import { formatRealEstateList } from "./utils/formatRealEstateList.utils";
import { useGetRealEstatesColumns } from "./hooks/useGetRealEstatesColumns";
import { RealEstatesFilters } from "./components/filters/RealEstatesFilters";
import { AddRealEstateModalForm } from "./components/modals/AddRealEstateModalForm";

const PER_PAGE = 20;

export const RealEstatesList: React.FC = () => {
  const [, showModal] = useStore("realEstate.edit.show_add_modal", false);
  const columnsList = useGetRealEstatesColumns();
  const translate = useTranslate();
  const redirect = useRedirect();

  const { data: realEstates, total } = useListController({
    perPage: PER_PAGE,
  });

  const columns = useMemo(
    () => (realEstates ? getColumns(columnsList, translate) : []),
    [realEstates, translate, columnsList]
  );

  const rows = useMemo(
    () => (realEstates ? formatRealEstateList(realEstates) : []),
    [realEstates]
  );

  const openModal = () => showModal(true);

  const handleCellClick = ({ id }: GridCellParams) => {
    redirect("edit", "real-estates", id);
  };

  const table = (
    <DataTable onCellClick={handleCellClick} columns={columns} rows={rows} />
  );

  return (
    <>
      <ListBase perPage={PER_PAGE}>
        <FilteredListLayout
          main={table}
          filters={<RealEstatesFilters />}
          title={translate("realEstate.list", { count: total ?? 0 })}
          addTitle={translate("realEstate.add")}
          onAddClick={openModal}
        />
      </ListBase>
      <AddRealEstateModalForm />
    </>
  );
};
