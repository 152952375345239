import React, { useCallback } from "react";

import { Locale } from "react-admin";
import { useLocaleState, useLocales } from "ra-core";
import LanguageIcon from "@mui/icons-material/Language";
import { Dropdown } from "ui/molecules/dropdown/Dropdown";

export const LocalesButton: React.FC = () => {
  const languages: Locale[] = useLocales();
  const [locale, setLocale] = useLocaleState();

  const changeLocale = (nextLocale: Locale) => {
    setLocale(nextLocale.locale);
  };

  const renderLocale = (itemLocale: Locale) => {
    return <>{itemLocale.name}</>;
  };

  const keyExtractor = useCallback((item: Locale) => item.locale, []);

  const selectedValue = languages.find((lang) => lang.locale === locale);

  return (
    <Dropdown
      items={languages}
      renderItem={renderLocale}
      onSelect={changeLocale}
      keyExtractor={keyExtractor}
      value={selectedValue}
    >
      <LanguageIcon />
    </Dropdown>
  );
};
