/**
 * @todo: Use introspection when ready
 */

export type PublishValidationException = {
  errors: string[];
  inner: ServerFieldValidationError[];
};

export type PublishValidationErrorBody = {
  exception: PublishValidationException;
};

export type PublishValidationError = Error & {
  body: PublishValidationErrorBody;
};

export interface ServerFieldValidationError {
  path: string;
  message: string;
}

export enum LocationType {
  Colocation = "Colocation",
  Simple = "Simple",
}

/** Apartment_Sharing, UNKNOWN, CommercialBuilding and Office are deprecated */
export enum PropertyKind {
  Apartment = "Apartment",
  Building = "Building",
  House = "House",
}

export interface RealEstateAction {
  slug: "archive" | "duplicate" | "askPublish" | "doPublish";
  openModal?: () => void;
  name: string;
  show: boolean;
  loading: boolean;
  callback?: (data?: any) => Promise<unknown> | void; // eslint-disable-line
}

export enum SynthesisTheme {
  EnergyPerformance = "ENERGY_PERFORMANCE",
  Student = "STUDENT",
  Employment = "EMPLOYMENT",
  Transport = "TRANSPORT",
  DiscountedFromZone = "DISCOUNTED_FROM_ZONE",
  LandValuation = "LAND_VALUATION",
  HighProfitability = "HIGH_PROFITABILITY",
  RentalTension = "RENTAL_TENSION",
  BrightApartment = "BRIGHT_APARTMENT",
  AdditionalLots = "ADDITIONAL_LOTS",
  CoOwnershipService = "CO_OWNERSHIP_SERVICE",
  CoOwnershipManagement = "CO_OWNERSHIP_MANAGEMENT",
  CoOwnershipWork = "CO_OWNERSHIP_WORK",
  PublicInvestmentProject = "PUBLIC_INVESTMENT_PROJECT",
  ExceptionalProperty = "EXCEPTIONAL_PROPERTY",
  SellerType = "SELLER_TYPE",
  PropertySituation = "PROPERTY_SITUATION",
  AlreadyRentedProperty = "ALREADY_RENTED_PROPERTY",
}

export interface SynthesisItem {
  theme: SynthesisTheme;
  description: string;
}
