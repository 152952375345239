import LightbulbIcon from "@mui/icons-material/Lightbulb";
import SettingsIcon from "@mui/icons-material/Settings";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import HandymanIcon from "@mui/icons-material/Handyman";

import { HelpCenterItem } from "./HelpCenter.types";

export const helpCenterList: HelpCenterItem[] = [
  {
    href: "https://www.notion.so/masteos/Les-process-4efe6da82400403e9d8d04e0338b62f2",
    icon: SettingsIcon,
    src: `${process.env.PUBLIC_URL}/images/helpCenter1.png`,
    title: "helpcenter.procedures",
  },
  {
    href: "https://www.notion.so/masteos/Les-outils-du-chasseur-6efac879356d4844af4c87eb821edf34",
    icon: HandymanIcon,
    src: `${process.env.PUBLIC_URL}/images/helpCenter2.png`,
    title: "helpcenter.tools",
  },
  {
    href: "https://www.notion.so/masteos/La-bible-de-chasse-b62c01ec24704be2987348a06f8e58fa",
    icon: MenuBookIcon,
    src: `${process.env.PUBLIC_URL}/images/helpCenter3.png`,
    title: "helpcenter.courses",
  },
  {
    href: "https://www.notion.so/La-relation-avec-les-conseillers-17c13087db234cf4920aedf40d9b128c",
    icon: LightbulbIcon,
    src: `${process.env.PUBLIC_URL}/images/helpCenter4.png`,
    title: "helpcenter.communication",
  },
];
