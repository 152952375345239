import { styled } from "@mui/material";

interface FieldsetProps {
  title?: string;
  children?: JSX.Element[] | JSX.Element;
}

const FieldsetContainer = styled("fieldset")`
  display: grid;
  grid-template-columns: 1fr;
  gap: 0;
  padding: 0;

  border: 0;
  &:has(> legend) {
    border-top: 1px solid ${({ theme }) => theme.palette.grey["400"]};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    grid-template-columns: 1fr 1fr;
    gap: 10px;

    & .fullColumns {
      grid-column: auto / span 2;
    }
    & .MuiFormControl-fullWidth {
      grid-column: auto / span 2;
    }
    & .MuiAutocomplete-fullWidth {
      grid-column: auto / span 2;
    }
    & .fieldset-helper-text {
      grid-column: auto / span 2;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;

    & .fullColumns {
      grid-column: auto / span 3;
    }
    & .MuiFormControl-fullWidth {
      grid-column: auto / span 3;
    }
    & .MuiAutocomplete-fullWidth {
      grid-column: auto / span 3;
    }
    & .fieldset-helper-text {
      grid-column: auto / span 3;
    }
  }
`;

const Legend = styled("legend")`
  margin-left: 20px;
  padding: 15px 10px;
  font-size: 12px;
  font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
  text-transform: uppercase;
`;

export const Fieldset: React.FC<FieldsetProps> = ({ title, children }) => {
  return (
    <FieldsetContainer>
      {!!title && <Legend>{title}</Legend>}
      {children}
    </FieldsetContainer>
  );
};
