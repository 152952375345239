import { SelectInput, useTranslate } from "react-admin";
import { Fieldset } from "ui/molecules/fieldset/Fieldset";
import { PriceInput } from "ui/atoms/price-input/PriceInput";
import { PercentInput } from "ui/atoms/percent-input/PercentInput";
import { AccordionForm } from "ui/molecules/accordion-form/AccordionForm";
import { Typography } from "@mui/material";

import { APPRECIATION_ZONE_DATA } from "constants/real-estates";

import { RealEstateEditAccordeonFormProps } from "./RealEstateEditAccordeonFormProps";

export const FinancialModelForm: React.FC<RealEstateEditAccordeonFormProps> = ({
  defaultExpanded,
}) => {
  const translate = useTranslate();
  return (
    <AccordionForm
      defaultExpanded={defaultExpanded}
      title={translate("realEstate.form.financialModel")}
    >
      <Fieldset title={translate("realEstate.fieldset.acquisition")}>
        <PriceInput
          source="financialModel.netSeller"
          label="financial.netSeller"
          margin="none"
          variant="outlined"
        />
        <PriceInput
          source="financialModel.agencySellerCommission"
          label="financial.agencySellerCommission"
          margin="none"
          variant="outlined"
        />
        <PriceInput
          source="financialModel.renovationAmount"
          label="financial.renovationAmount"
          margin="none"
          variant="outlined"
        />
        <PriceInput
          source="financialModel.furnituresAmount"
          label="financial.furnituresAmount"
          margin="none"
          variant="outlined"
        />
      </Fieldset>
      <Fieldset title={translate("realEstate.fieldset.revenues")}>
        <PriceInput
          source="financialModel.annualRentIncome"
          label="financial.annualRentIncome"
          margin="none"
          variant="outlined"
        />
        <PriceInput
          source="financialModel.annualCharges"
          label="financial.annualCharges"
          margin="none"
          variant="outlined"
        />
        <PercentInput
          source="financialModel.evaluationRate"
          label="financial.evaluationRate"
          margin="none"
          variant="outlined"
        />
        <SelectInput
          source="appreciationZone"
          label="realEstate.input.appreciationZone"
          choices={APPRECIATION_ZONE_DATA}
          margin="none"
          variant="outlined"
        />
      </Fieldset>
      <Fieldset title={translate("realEstate.fieldset.expenses")}>
        <PriceInput
          source="financialModel.annualCurrentExpenses"
          label="financial.annualCurrentExpenses"
          margin="none"
          variant="outlined"
        />
        <PriceInput
          source="financialModel.annualPropertyTax"
          label="financial.annualPropertyTax"
          margin="none"
          variant="outlined"
        />
        <PriceInput
          source="financialModel.annualHomeOwnerInsurance"
          label="financial.annualHomeOwnerInsurance"
          margin="none"
          variant="outlined"
        />
      </Fieldset>
      <Fieldset title={translate("realEstate.fieldset.financialPerformance")}>
        <PriceInput
          source="financialModel.projectAmount"
          label="financial.projectAmount"
          margin="none"
          variant="outlined"
          disabled
        />
        <PriceInput
          source="financialModel.annualTotalIncome"
          label="financial.annualTotalIncome"
          margin="none"
          variant="outlined"
          disabled
        />
        <PriceInput
          source="financialModel.annualTotalExpenses"
          label="financial.annualTotalExpenses"
          margin="none"
          variant="outlined"
          disabled
        />
        <PriceInput
          source="financialModel.annualLoanPayments"
          label="financial.annualLoanPayments"
          margin="none"
          variant="outlined"
          disabled
        />
        <PriceInput
          source="financialModel.annualTotalEarnings"
          label="financial.annualTotalEarnings"
          margin="none"
          variant="outlined"
          disabled
        />
        <PercentInput
          source="financialModel.grossReturn"
          label="financial.grossReturn"
          margin="none"
          variant="outlined"
          disabled
        />
        <PercentInput
          source="financialModel.netReturn"
          label="financial.netReturn"
          margin="none"
          variant="outlined"
          disabled
        />
        <Typography variant="caption" className="fieldset-helper-text" p={1}>
          {translate("realEstate.fieldset.financialPerformance_helperText")}
        </Typography>
      </Fieldset>
    </AccordionForm>
  );
};
