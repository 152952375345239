import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";

interface AccordionFormProps {
  title: string;
  children: JSX.Element | JSX.Element[];
  defaultExpanded?: boolean;
}

export const AccordionForm: React.FC<AccordionFormProps> = ({
  title,
  children,
  defaultExpanded,
}) => (
  <Accordion defaultExpanded={defaultExpanded}>
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      <Typography variant="h6">{title}</Typography>
    </AccordionSummary>
    <AccordionDetails>{children}</AccordionDetails>
  </Accordion>
);
