import * as yup from "yup";

export const passwordSchema = yup.object().shape({
  password: yup
    .string()
    .required("forgotPassword.stepRenewPassword.passwordMinLength")
    .min(8, "forgotPassword.stepRenewPassword.passwordMinLength")
    .typeError("forgotPassword.stepRenewPassword.passwordMinLength"),
});

export const codeSchema = yup.object().shape({
  code: yup
    .string()
    .typeError("forgotPassword.stepCode.onlyDigits")
    .matches(/^\d+$/, "forgotPassword.stepCode.onlyDigits")
    .min(6, "forgotPassword.stepCode.codeShouldBeSixDigit")
    .max(8, "forgotPassword.stepCode.codeShouldBeSixDigit")
    .required(),
});
