import React from "react";

import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import { App } from "./App";
//import { reportWebVitals } from "./reportWebVitals";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.NODE_ENV,
  // see https://forum.sentry.io/t/resizeobserver-loop-limit-exceeded/8402/3
  ignoreErrors: [
    "ResizeObserver loop limit exceeded", // CHROME
    "ResizeObserver loop completed with undelivered notifications", // FIREFOX
  ],
  integrations: [new BrowserTracing()],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: parseFloat(
    process.env.REACT_APP_SENTRY_TRACE_SAMPLE_RATE || "0"
  ),
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
