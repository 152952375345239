import { useCallback, useState } from "react";

import ImageIcon from "@mui/icons-material/Image";
import { Box, styled } from "@mui/material";

export type ImageType = {
  src: string;
  fit?: string;
};

const Default = styled(Box)`
  background-color: ${({ theme }) => theme.palette.background.paper};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const ImageHtml = styled("img")`
  object-position: 50% 50%;
  ${({ $fit }: { $fit: string }) => ($fit ? `object-fit: ${$fit};` : "")}

  width: 100%;
  height: 100%;
`;

export const Image: React.FC<ImageType> = ({ src, fit, ...rest }) => {
  const [errSrc, setErrSrc] = useState<string | undefined | null>();

  const onError = useCallback(() => {
    setErrSrc(src);
  }, [src]);

  return (
    <>
      {src && src !== errSrc ? (
        <ImageHtml
          src={src}
          onError={onError}
          $fit={fit || "cover"}
          {...rest}
          data-testid="image"
        />
      ) : (
        <Default data-testid="defaultImage" {...rest}>
          <ImageIcon />
        </Default>
      )}
    </>
  );
};
