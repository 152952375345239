import { useEditContext, useLocaleState, useTranslate } from "react-admin";
import { CircularProgress, Typography } from "@mui/material";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import EuroIcon from "@mui/icons-material/Euro";
import { MetricBlock } from "ui/molecules/metric-block/MetricBlock";
import { PriceField } from "ui/atoms/price-field/PriceField";
import { PercentField } from "ui/atoms/percent-field/PercentField";
import { formatDate } from "utils/i18n/i18n.utils";
import { Header } from "ui/organisms/header/Header";
import { formatFullName } from "utils/strings/strings.utils";
import { useGenerateTitle } from "features/realEstates/edit/hooks/useGenerateTitle";

import { ActionButtons } from "../action-buttons/ActionButtons";

// @todo: handle automatique title update based on form values
export const PropertyHeader: React.FC = () => {
  const { record, isLoading } = useEditContext();
  const translate = useTranslate();
  const [locale] = useLocaleState();

  const title = useGenerateTitle();

  if (isLoading || !record) {
    return <CircularProgress />;
  }

  const { createdAt, updatedAt, financialModel, hunter, id, status } = record;

  const createdAtString = formatDate({
    date: createdAt,
    locale,
    options: { hour: undefined, minute: undefined },
  });
  const updatedAtString = updatedAt
    ? formatDate({ date: updatedAt, locale })
    : null;
  const timeStampString = `${translate("shared.createdOn", {
    date: createdAtString,
    interpolation: { escapeValue: false },
  })} ${
    updatedAtString
      ? ` - ${translate("shared.editedOn", {
          date: updatedAtString,
          interpolation: { escapeValue: false },
        })}`
      : ""
  }`;

  const publicUrl = `${process.env.REACT_APP_MASTEOS_PUBLIC_URL}/property/${id}`;

  const propertyDetails = (
    <>
      <Typography variant="caption">
        {translate("shared.addedBy", {
          fullName: formatFullName(hunter.firstName, hunter.lastName),
        })}
      </Typography>
      <Typography variant="caption">{timeStampString}</Typography>
    </>
  );

  const propertyMetrics = financialModel && (
    <>
      <MetricBlock
        icon={<EuroIcon />}
        text={translate("financial.totalBudget")}
        value={<PriceField price={financialModel.projectAmount} />}
      />
      <MetricBlock
        icon={<AccountBalanceWalletIcon />}
        text={translate("financial.grossReturn")}
        value={<PercentField percent={financialModel.grossReturn} />}
      />
    </>
  );

  return (
    <Header
      testId="property-header"
      title={title}
      status={status}
      linkTo={publicUrl}
      actionButtons={<ActionButtons />}
      details={propertyDetails}
      metrics={propertyMetrics}
    />
  );
};
