export interface ArchiveBody {
  archivingReason: string;
}

export enum ResetPasswordOrigin {
  HeliosUserCreation = "HELIOS_USER_CREATION",
  HeliosUser = "HELIOS_USER",
}

export interface ForgotPasswordBody {
  email: string;
  language?: string;
  origin: ResetPasswordOrigin;
}

export interface ResetPasswordBody {
  code: string;
  email: string;
}
export interface ResetPasswordResponseBody {
  hash: string;
}

export interface RenewPasswordBody {
  password: string;
  email: string;
  hash: string;
}
