import React, { useCallback, useEffect, useState } from "react";

import {
  ArrayInput,
  SelectInput,
  SimpleFormIterator as SimpleFormIteratorRA,
  useInput,
  useTranslate,
} from "react-admin";
import { Button, styled } from "@mui/material";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import { SynthesisItem, SynthesisTheme } from "types/realEstate.types";
import { TextArea } from "ui/atoms/textarea/TextArea";
import { TFunction } from "react-i18next";

type choiceType = {
  disabled: boolean;
  id: string;
  name: string;
};

const SimpleFormIterator = styled(SimpleFormIteratorRA)`
  & .RaSimpleFormIterator-line {
    border-bottom: 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    & .RaSimpleFormIterator-inline {
      display: grid;
      grid-template-columns: 0.5fr 1fr;

      & .MuiFormControl-root {
        grid-column: auto;
      }
    }
  }
`;

const getThemeChoices = (translate: TFunction): choiceType[] => {
  return Object.entries(SynthesisTheme)
    .map(([_, value]) => ({
      disabled: false,
      id: value,
      name: translate(`realEstate.synthesis.theme.${value.toLowerCase()}`),
    }))
    .sort((a, b) => a.name.localeCompare(b.name));
};

export const SynthesisInput: React.FC = () => {
  const translate = useTranslate();
  const { field } = useInput({ defaultValue: [], source: "synthesis" });

  const [choices, setChoices] = useState(getThemeChoices(translate));
  const [isValid, setIsValid] = useState<boolean>(true);

  const handleIsValid = useCallback(() => {
    setIsValid(
      !field.value?.some(
        ({ theme, description }: SynthesisItem) => !theme || !description
      ) && field.value.length < 4
    );
  }, [field.value]);

  const handleSetChoices = useCallback(() => {
    setChoices((currentChoices) =>
      currentChoices.map((choice: choiceType) => ({
        ...choice,
        disabled: field.value?.some(
          (val: SynthesisItem) => val.theme === choice.id
        ),
      }))
    );
    handleIsValid();
  }, [field.value, handleIsValid]);

  useEffect(() => {
    handleSetChoices();
    handleIsValid();
  }, [handleIsValid, handleSetChoices]);

  return (
    <ArrayInput source="synthesis" label={false} className="fullColumns">
      <SimpleFormIterator
        inline
        fullWidth
        addButton={
          <Button
            sx={{ padding: "10px" }}
            startIcon={<ControlPointIcon />}
            variant="outlined"
            disabled={!isValid}
            fullWidth
          >
            {translate("realEstate.synthesis.addThemeLabel")}
          </Button>
        }
      >
        <SelectInput
          label="realEstate.input.synthesisTheme"
          source="theme"
          variant="outlined"
          onBlur={handleSetChoices}
          choices={choices}
        />
        <TextArea
          source="description"
          label="realEstate.input.synthesisDescription"
          minRows={2}
          onBlur={handleIsValid}
        />
      </SimpleFormIterator>
    </ArrayInput>
  );
};
