import { RaRecord } from "react-admin";

export const sanitizeData = (data: RaRecord): RaRecord => {
  const sanitizedData = {} as RaRecord;
  for (const key in data) {
    if (typeof data[key] === "string" && data[key].trim().length === 0) {
      data[key] = null;
    }
    sanitizedData[key] = data[key];
  }

  return sanitizedData;
};

export const sanitizeRecord = (data: RaRecord): RaRecord => {
  const sanitizedRecord = {} as RaRecord;
  for (const key in data) {
    if (data[key] === null) {
      data[key] = "";
    }
    sanitizedRecord[key] = data[key];
  }

  return sanitizedRecord;
};

interface Address {
  street: string;
  city: string;
  country: string;
}

export const formatFullStreet = (address: Address): string => {
  return `${address.street}, ${address.city}, ${address.country}`;
};
