import * as yup from "yup";

import { ROLES } from "constants/user";

export const userForm = yup.object().shape({
  cityManagerId: yup.string().when("roles", (roles: string[], schema) => {
    if (Array.isArray(roles) && roles.includes("hunter")) {
      return schema.required("City manager is required");
    }
  }),
  email: yup.string().email().required(),
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  roles: yup
    .array()
    .test(
      (values) =>
        Array.isArray(values) &&
        !!values.length &&
        values.every((value) => ROLES.find((role) => role.value === value))
    )
    .required(),
});
