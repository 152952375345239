import { Box, Divider, IconButton, styled, Typography } from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import { Status } from "types/other";
import { StatusChip } from "ui/atoms/status-chip/StatusChip";

const HeaderContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0;
  width: 100%;

  position: sticky;
  top: ${({ theme }) => theme.mixins.toolbar.minHeight}px;
  padding: 16px 0 0;
  z-index: 100;
  background-color: ${({ theme }) => theme.palette.background.default};

  & .MuiDivider-root {
    margin-top: 8px;
  }
`;

const HeaderTitle = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  gap: 16px;
  width: 100%;

  & a {
    color: ${({ theme }) => theme.palette.common.black};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    padding: 0;
  }
`;

const HeaderDetails = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  gap: 4px;

  @media (min-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    flex-direction: row;

    & .MuiTypography-caption:not(:last-child):after {
      content: " -";
    }
  }
`;

const HeaderMetrics = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px 0 0;
  gap: 24px;
`;

const HeaderActionButtons = styled(Box)`
  margin-left: auto;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    display: none;
  }
`;

interface HeaderProps {
  title: string;
  status?: Status;
  linkTo?: string;
  actionButtons?: JSX.Element;
  details?: JSX.Element;
  metrics?: JSX.Element;
  testId?: string;
}

export const Header: React.FC<HeaderProps> = ({
  title,
  status,
  linkTo,
  actionButtons,
  details,
  metrics,
  testId,
}) => {
  return (
    <HeaderContainer role="heading" data-testid={testId}>
      <HeaderTitle>
        <>
          <Typography variant="h6">{title}</Typography>
          {!!linkTo && (
            <a href={linkTo} target="_blank" rel="noopener noreferrer">
              <IconButton aria-label="link" color="inherit">
                <LaunchIcon />
              </IconButton>
            </a>
          )}
          {!!status && <StatusChip status={status} />}
          {!!actionButtons && (
            <HeaderActionButtons display="flex" gap={2}>
              {actionButtons}
            </HeaderActionButtons>
          )}
        </>
      </HeaderTitle>
      {!!details && <HeaderDetails>{details}</HeaderDetails>}
      {!!metrics && <HeaderMetrics>{metrics}</HeaderMetrics>}
      <Divider flexItem variant="fullWidth" />
    </HeaderContainer>
  );
};
