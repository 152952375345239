import { Fieldset } from "ui/molecules/fieldset/Fieldset";
import { useTranslate } from "react-admin";
import { ImageField } from "ui/organisms/image-field/ImageField";
import { AccordionForm } from "ui/molecules/accordion-form/AccordionForm";
import { TextArea } from "ui/atoms/textarea/TextArea";

import { RealEstateEditAccordeonFormProps } from "./RealEstateEditAccordeonFormProps";

export const MiscForm: React.FC<RealEstateEditAccordeonFormProps> = ({
  defaultExpanded,
}) => {
  const translate = useTranslate();

  return (
    <AccordionForm
      defaultExpanded={defaultExpanded}
      title={translate("realEstate.form.misc")}
    >
      <Fieldset title={translate("realEstate.fieldset.exterior")}>
        <TextArea
          fullWidth
          label="realEstate.input.outsideDescription"
          source="outside.description"
        />
      </Fieldset>
      <ImageField source="outside.images" />
      <Fieldset title={translate("realEstate.fieldset.common")}>
        <TextArea
          fullWidth
          label="realEstate.input.commonDescription"
          source="common.description"
        />
      </Fieldset>
      <ImageField source="common.images" />
      <Fieldset title={translate("realEstate.fieldset.inside")}>
        <TextArea
          fullWidth
          label="realEstate.input.insideDescription"
          source="inside.description"
        />
      </Fieldset>
      <ImageField source="inside.images" />
    </AccordionForm>
  );
};
