import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import defaultFRLang from "ra-language-french";
import defaultENLang from "ra-language-english";
// import defaultESLang from "@blackbox-vision/ra-language-spanish";
import { frFR, frBE, esES, enUS } from "langs";
import { I18nProvider, Locale } from "react-admin";
import LanguageDetector from "i18next-browser-languagedetector";

import { languages } from "constants/languages";

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    debug: process.env.REACT_APP_I18N_DEBUG === "true",
    detection: {
      lookupLocalStorage: "RaStore.locale",
    },
    fallbackLng: languages.enUS,
    resources: {
      [languages.enUS]: {
        translation: {
          ...defaultENLang,
          ...enUS,
        },
      },
      [languages.frFR]: {
        translation: {
          ...defaultFRLang,
          ...frFR,
        },
      },
      [languages.frBE]: {
        translation: {
          ...defaultFRLang,
          ...frBE,
        },
      },
      [languages.esES]: {
        translation: {
          ...esES,
        },
      },
    },
  });

export const getLocales = (): Locale[] => {
  return [
    { locale: languages.frFR, name: "French" },
    { locale: languages.frBE, name: "French (Belgium)" },
    { locale: languages.enUS, name: "English (US)" },
    { locale: "cimode", name: "Debug" },
  ];
};

export const getI18nProvider = (lang?: string): I18nProvider => {
  if (lang) {
    i18n.changeLanguage(lang);
  }

  return {
    changeLocale: async (locale: string) => {
      await i18n.changeLanguage(locale);
    },
    getLocale: () => i18n.language,
    getLocales,
    translate: (key, options) => {
      const translation = i18n.t(key, options);
      return translation.toString();
    },
  };
};
