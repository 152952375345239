import { useCallback } from "react";

import {
  Edit,
  Form,
  useRedirect,
  RaRecord,
  useNotify,
  EditContext,
} from "react-admin";
import { yupResolver } from "@hookform/resolvers/yup";
import { styled } from "@mui/material";
import { MobileFooter } from "components/mobile-footer/MobileFooter";
import {
  formatFullStreet,
  sanitizeData,
  sanitizeRecord,
} from "utils/form/form.utils";
import { getInternalNoteTemplate } from "features/realEstates/utils/getInternalNoteTemplate";

import { PropertyHeader } from "./components/property-header/PropertyHeader";
import { EditForm } from "./components/edit-form/EditForm";
import { propertyEditForm } from "./RealEstateEdit.validator";
import { ArchiveActionModal } from "./components/action-modal/ArchiveActionModal";
import { ActionContextProvider } from "./context/ActionContextProvider";
import { ActionButtons } from "./components/action-buttons/ActionButtons";
import { AddRealEstateModalForm } from "../list/components/modals/AddRealEstateModalForm";
import {
  transformForDisplay,
  transformForSave,
} from "../utils/payloadTransforms";

const EditContainer = styled(Edit)`
  width: 100%;

  .RaEdit-card {
    width: 100%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.values.lg}px) {
    margin-left: 64px;
    margin-right: 64px;
  }
`;

interface ErrorResponse {
  body: { message: string };
}

export const RealEstateEdit: React.FC = () => {
  const redirect = useRedirect();
  const notify = useNotify();

  const onSuccess = (data: RaRecord) => {
    notify("realEstate.edit.success", { type: "success" });
    redirect("edit", "real-estates", data._id);
  };

  const onError = (err: unknown) => {
    const error = err as ErrorResponse;
    notify(error?.body?.message || "realEstate.edit.error", { type: "error" });
  };

  const handleTransform = useCallback(
    (data: RaRecord) =>
      transformForSave(
        sanitizeData({
          ...data,
          address: {
            ...data.address,
            street: data.geocode?.street || formatFullStreet(data.address),
          },
          internalNote: data.internalNote || getInternalNoteTemplate(data.lang),
        })
      ),
    []
  );

  return (
    <EditContainer
      component="div"
      mutationMode="pessimistic"
      mutationOptions={{ onError, onSuccess }}
      transform={handleTransform}
    >
      <ActionContextProvider>
        <EditContext.Consumer>
          {({ record }) => (
            <Form
              resolver={yupResolver(propertyEditForm)}
              warnWhenUnsavedChanges
              mode="onBlur"
              record={
                record ? transformForDisplay(sanitizeRecord(record)) : undefined
              }
            >
              <PropertyHeader />
              <EditForm />
              <MobileFooter>
                <ActionButtons />
              </MobileFooter>
            </Form>
          )}
        </EditContext.Consumer>
        <ArchiveActionModal />
        <AddRealEstateModalForm />
      </ActionContextProvider>
    </EditContainer>
  );
};
