import { AutocompleteValue } from "ui/molecules/autocomplete-input/AutocompleteInput";

import {
  IRealEstatesFilters,
  HunterData,
  CityData,
} from "../RealEstateFilters.types";

export const updateHunters = (
  filters: IRealEstatesFilters,
  hunter: Nullable<AutocompleteValue<HunterData>>
): IRealEstatesFilters => {
  if (!hunter) {
    return filters;
  }

  const hunterFound = filters?.hunters?.find(
    (h: HunterData) => h.id === hunter.rawData?.id
  );

  const hunters =
    filters?.hunters?.filter((h: HunterData) => h.id !== hunter.rawData?.id) ??
    [];

  if (!hunterFound && hunter?.rawData) {
    hunters.push(hunter.rawData);
  }

  return { ...filters, hunters: hunters };
};

export const updateCities = (
  filters: IRealEstatesFilters,
  city: Nullable<AutocompleteValue<CityData>>
): IRealEstatesFilters => {
  if (!city) {
    return filters;
  }

  const cityFound = filters?.cities?.find((c: CityData) => c.id === city.value);

  const cities =
    filters?.cities?.filter((c: CityData) => c.id !== city.value) ?? [];

  if (!cityFound && city?.value) {
    cities.push({ id: city.value });
  }

  return { ...filters, cities: cities };
};
