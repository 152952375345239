import { Typography, Box } from "@mui/material";
import { ImageCard } from "ui/molecules/image-card/ImageCard";
import { styled } from "@mui/material/styles";
import SupportIcon from "@mui/icons-material/Support";
import { useTranslate } from "react-admin";

import { HelpCenterItem } from "./HelpCenter.types";
import { helpCenterList } from "./HelpCenter.constants";

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0 10px;

  a {
    text-decoration: none;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    margin: 0 80px;
  }
`;

const Wrapper = styled(Box)`
  width: 100%;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.common.white};
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  padding-bottom: 69px;
`;

const Content = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 23px;
  max-width: 650px;
`;

const ContentWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  margin-top: 19px;
  padding: 0 20px;

  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    margin-top: 49px;
  }
`;

const Description = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

const List = styled(Box)`
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;

  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
`;

const TopHeader = styled(Box)`
  display: flex;
  flex-direction: row;
  margin-top: 37px;
  margin-left: 18px;
  align-items: center;
  gap: 5px;
`;

export const HelpCenter: React.FC = () => {
  const translate = useTranslate();

  return (
    <Container>
      <Wrapper>
        <TopHeader>
          <SupportIcon />
          <Typography variant="h6">{translate("shared.helpcenter")}</Typography>
        </TopHeader>
        <ContentWrapper>
          <Content>
            <Typography variant="h4" data-testid="helpcentertitle">
              {translate("helpcenter.title")}
            </Typography>

            <Description>
              <SupportIcon />
              <Typography variant="h6" data-testid="helpcenterdescription">
                {translate("helpcenter.description")}
              </Typography>
            </Description>

            <List data-testid="helpcenterlist">
              {helpCenterList.map(
                ({ icon: Icon, ...helpCenterItem }: HelpCenterItem) => (
                  <a
                    href={helpCenterItem.href}
                    key={helpCenterItem.title}
                    data-testid="helpcenterlitem"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <ImageCard
                      src={helpCenterItem.src}
                      icon={<Icon />}
                      title={translate(helpCenterItem.title)}
                    />
                  </a>
                )
              )}
            </List>
          </Content>
        </ContentWrapper>
      </Wrapper>
    </Container>
  );
};
