import { useMemo } from "react";

import {
  Admin as RAdmin,
  Authenticated,
  CustomRoutes,
  Resource,
} from "react-admin";
import { realEstates } from "features/realEstates/realEstates.provider";
import { users } from "features/users/users.provider";
import { AppLayout } from "ui/layouts/app-layout/AppLayout";
import { auth } from "features/auth/auth.provider";
import { HelpCenter } from "features/helpCenter/HelpCenter";
import { Notifier } from "components/notifier/Notifier";
import { Route } from "react-router-dom";
import { ResetPassword } from "features/resetPassword/ResetPassword";
import { StepEmail } from "features/resetPassword/stepEmail/StepEmail";
import { StepCode } from "features/resetPassword/stepCode/StepCode";
import { StepRenewPassword } from "features/resetPassword/stepRenewPassword/StepRenewPassword";

import { dataProvider } from "./dataProvider";
import { authProvider } from "./authProvider";
import { getI18nProvider } from "./i18nProvider";
import { themeProvider } from "./themeProvider";

export const Admin: React.FC = () => {
  const i18nProvider = useMemo(() => getI18nProvider(), []);

  return (
    <RAdmin
      notification={Notifier}
      dataProvider={dataProvider}
      authProvider={authProvider}
      i18nProvider={i18nProvider}
      theme={themeProvider}
      layout={AppLayout}
      loginPage={auth.login}
    >
      <Resource name="real-estates" {...realEstates} />
      <Resource name="users" {...users} />

      <CustomRoutes>
        <Route
          path="/help-center"
          element={
            <Authenticated requireAuth>
              <HelpCenter />
            </Authenticated>
          }
        />
      </CustomRoutes>
      <CustomRoutes>
        <Route path="reset-password" element={<ResetPassword />}>
          <Route path="email" element={<StepEmail />} />
          <Route path="code" element={<StepCode />} />
          <Route path="new-password" element={<StepRenewPassword />} />
        </Route>
      </CustomRoutes>
    </RAdmin>
  );
};
