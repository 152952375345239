import { Box, Button, Modal as MUIModal, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Close as CloseIcon } from "@mui/icons-material";

const ModalContent = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 95%;
  background-color: white;
  box-shadow: 1px 2px 0px rgba(0, 0, 0, 0.14);
  border-radius: 6px;
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (min-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    width: 75%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    width: 45%;
  }
`;

const FooterContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const HeaderContainer = styled(Box)`
  display: flex;
  flex-direction: row;
`;

const Title = styled(Typography)`
  flex: 1;
`;

const CloseButton = styled(Button)`
  color: ${({ theme }) => theme.palette.grey["600"]};
  min-width: unset;
  padding: 0;
  margin: 0;
  background-color: transparent;
  &:hover {
    background-color: transparent;
  }
`;

interface ModalProps {
  open: boolean;
  onClose: () => void;
  showCloseButton?: boolean;
  title: string;
  footer: JSX.Element;
}

export const Modal: React.FC<React.PropsWithChildren<ModalProps>> = (props) => {
  const { open, onClose, title, children, footer, showCloseButton } = props;

  return (
    <MUIModal open={open} onClose={onClose}>
      <ModalContent data-testid="modal-content">
        <HeaderContainer>
          <Title variant="h6" sx={{ fontWeight: "bold" }}>
            {title}
          </Title>
          {!!showCloseButton && (
            <CloseButton variant="text" onClick={onClose}>
              <CloseIcon />
            </CloseButton>
          )}
        </HeaderContainer>

        <Box>{children}</Box>

        <FooterContainer>{footer}</FooterContainer>
      </ModalContent>
    </MUIModal>
  );
};
