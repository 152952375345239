import React from "react";

import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  useTranslate,
  Create as RACreate,
  useCreateController,
  useNotify,
  useGetList,
} from "react-admin";
import { Modal } from "ui/organisms/modal/Modal";
import { Button } from "ui/atoms/button/Button";
import { LoadingButton } from "ui/molecules/loading-button/LoadingButton";

import { UserForm } from "./UserForm";

const FooterContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const Create = styled(RACreate)`
  & .MuiPaper-root {
    box-shadow: none;
    border: none;
  }
`;

const ButtonsContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: ${(props) => props.theme.spacing(2)};
`;

interface UserModalFormProps {
  open: boolean;
  onClose: () => void;
  onSuccess?: (id: string) => void;
}

export const AddUserModalForm: React.FC<UserModalFormProps> = ({
  open,
  onClose,
  onSuccess,
}) => {
  const translate = useTranslate();
  const notify = useNotify();
  const { save, isLoading, saving } = useCreateController({
    resource: "users",
  });

  const { data: dataCitiesManager } = useGetList("users/citiesmanagers");

  const computedCitiesManager = dataCitiesManager?.map((cityManager) => ({
    label: cityManager.name,
    value: cityManager.id,
  }));

  const handleSubmit = async (data: Record<string, unknown>) => {
    if (save) {
      save(data, {
        onError: (err) => {
          notify(err?.body?.message || "user.create.error", {
            type: "error",
          });
        },
        onSuccess: (response) => {
          onSuccess && onSuccess(response.id);
          notify("user.form.successToaster", { type: "success" });
        },
      });
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={translate("users.add")}
      footer={
        <FooterContainer>
          <ButtonsContainer>
            <Button variant="text" onClick={onClose}>
              {translate("ra.action.cancel")}
            </Button>
            <LoadingButton
              loading={isLoading || saving}
              variant="text"
              type="submit"
              form="user-form"
              data-testid="user-form-submit-button"
            >
              {translate("ra.action.add")}
            </LoadingButton>
          </ButtonsContainer>
        </FooterContainer>
      }
    >
      <Create>
        <UserForm
          handleSubmit={handleSubmit}
          cityManagers={computedCitiesManager}
        />
      </Create>
    </Modal>
  );
};
