import React from "react";

import { CircularProgress, styled } from "@mui/material";

const LoadingContainer = styled("div")`
  position: relative;
`;

const LoadingProgress = styled(CircularProgress)`
  position: absolute;
  left: 50%;
  margin-left: -12px;
  margin-top: -12px;
  top: 50%;
`;

interface LoadingButtonProps {
  loading?: boolean;
  children: React.ReactNode;
}

export const withLoader = <P,>(
  WrappedComponent: React.FC<P>
): React.FC<P & LoadingButtonProps> => {
  const WithLoader: React.FC<P & LoadingButtonProps> = ({
    loading,
    children,
    ...other
  }) => {
    return (
      <LoadingContainer>
        <WrappedComponent {...(other as P)}>{children}</WrappedComponent>
        {!!loading && <LoadingProgress size={24} role="loader" />}
      </LoadingContainer>
    );
  };
  return WithLoader;
};
