import { Box, styled } from "@mui/material";
import { useEditContext } from "react-admin";

import { LocalizationForm } from "./LocalizationForm";
import { FinancialModelForm } from "./FinancialModelForm";
import { ProjectForm } from "./ProjectForm";
import { RenovationForm } from "./RenovationForm";
import { OverviewForm } from "./OverviewForm";
import { AccessForm } from "./AccessForm";
import { MiscForm } from "./MiscForm";
import { MarketDataForm } from "./MarketDataForm";
import { SynthesisForm } from "./SynthesisForm";
import { InternalNoteForm } from "./InternalNoteForm";

const FormContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  margin-bottom: 24px;
  padding: 0 5px;
  gap: 16px;

  @media (min-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    margin-top: 24px;
    margin-left: 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.values.lg}px) {
    max-width: 70%;
  }

  & .Mui-disabled {
    cursor: not-allowed;
  }
`;

export const EditForm: React.FC = () => {
  const { record } = useEditContext();
  const expandAll = ["DRAFT", "IN_REVIEW"].includes(record.status);
  return (
    <FormContainer>
      <InternalNoteForm defaultExpanded={expandAll} />
      <SynthesisForm defaultExpanded={expandAll} />
      <ProjectForm defaultExpanded={expandAll} />
      <LocalizationForm defaultExpanded={expandAll} />
      <FinancialModelForm defaultExpanded={expandAll} />
      <OverviewForm defaultExpanded={expandAll} />
      <MiscForm defaultExpanded={expandAll} />
      <RenovationForm defaultExpanded={expandAll} />
      <AccessForm defaultExpanded={expandAll} />
      <MarketDataForm defaultExpanded={expandAll} />
    </FormContainer>
  );
};
