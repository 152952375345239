interface GetAddressComponentsReturn {
  country?: string;
  countryCode?: string;
  street?: string;
  streetNumber?: string;
  city?: string;
  regionName?: string;
  postalCode?: string;
}

// @todo: get types from googleMaps
export const getAddressComponents = (
  geocodeResult: google.maps.GeocoderResult
): GetAddressComponentsReturn => {
  const retVal: GetAddressComponentsReturn = {};

  geocodeResult.address_components.forEach((comp) => {
    if (comp.types.includes("country")) {
      retVal.country = comp.long_name;
      retVal.countryCode = comp.short_name;
    }
    if (comp.types.includes("route")) {
      retVal.street = comp.long_name;
    }
    if (comp.types.includes("street_number")) {
      retVal.streetNumber = comp.long_name;
    }
    if (comp.types.includes("locality")) {
      retVal.city = comp.long_name;
    }
    if (comp.types.includes("administrative_area_level_1")) {
      retVal.regionName = comp.long_name;
    }
    if (comp.types.includes("postal_code")) {
      retVal.postalCode = comp.long_name;
    }
  });

  return retVal;
};
