import { Fieldset } from "ui/molecules/fieldset/Fieldset";
import { useTranslate } from "react-admin";
import { ImageField } from "ui/organisms/image-field/ImageField";
import { AccordionForm } from "ui/molecules/accordion-form/AccordionForm";
import { TextArea } from "ui/atoms/textarea/TextArea";

import { RealEstateEditAccordeonFormProps } from "./RealEstateEditAccordeonFormProps";

export const AccessForm: React.FC<RealEstateEditAccordeonFormProps> = ({
  defaultExpanded,
}) => {
  const translate = useTranslate();

  return (
    <AccordionForm
      defaultExpanded={defaultExpanded}
      title={translate("realEstate.form.access")}
    >
      <Fieldset>
        <TextArea
          fullWidth
          label="realEstate.input.accessDescription"
          source="access.description"
        />
        <TextArea
          fullWidth
          label="realEstate.input.neighborhoodDescription"
          source="neighborhood.description"
        />
      </Fieldset>
      <Fieldset title={translate("realEstate.fieldset.imagesNeighborhood")} />
      <ImageField source="neighborhood.images" />
    </AccordionForm>
  );
};
