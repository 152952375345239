import { RaRecord } from "react-admin";

export const transformForSave = (data: RaRecord): RaRecord => {
  return {
    ...data,
    financialModel: {
      ...data.financialModel,
      evaluationRate: parseFloat(
        (data.financialModel?.evaluationRate / 100).toFixed(3)
      ),
    },
  };
};

export const transformForDisplay = (data: RaRecord): RaRecord => {
  return {
    ...data,
    financialModel: {
      ...data.financialModel,
      evaluationRate: data.financialModel?.evaluationRate
        ? parseFloat((data.financialModel.evaluationRate * 100).toFixed(2))
        : null,
    },
  };
};
