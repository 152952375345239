import { useCallback } from "react";

import { useSnackbar, OptionsObject, SnackbarKey } from "notistack";

import { getSnackBarAction } from "./useNotification.utils";
export type { SnackbarKey } from "notistack";

interface CustomOptionsObject extends OptionsObject {
  closable?: boolean;
}

export interface OpenNotificationType {
  message: string;
  options?: CustomOptionsObject;
}

export const useNotification = (): {
  close: (key?: SnackbarKey) => void;
  openError: (args: OpenNotificationType) => SnackbarKey;
  openSuccess: (args: OpenNotificationType) => SnackbarKey;
  openWarning: (args: OpenNotificationType) => SnackbarKey;
  openDefault: (args: OpenNotificationType) => SnackbarKey;
} => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const notify = useCallback(
    ({ message, options }: OpenNotificationType) => {
      const { closable, ...rest } = options || {};
      const snackBarOptions: OptionsObject = {
        autoHideDuration: 2000,
        ...rest,
        ...getSnackBarAction({ closable, closeSnackbar }),
        anchorOrigin: {
          horizontal: "right",
          vertical: "bottom",
        },
      };
      return enqueueSnackbar(message, snackBarOptions);
    },
    [enqueueSnackbar, closeSnackbar]
  );

  const openSuccess = useCallback(
    ({ options = {}, ...rest }: OpenNotificationType) => {
      return notify({ ...rest, options: { ...options, variant: "success" } });
    },
    [notify]
  );

  const openError = useCallback(
    ({ options = {}, ...rest }: OpenNotificationType) => {
      return notify({
        ...rest,
        options: {
          ...options,
          autoHideDuration: null,
          closable: true,
          variant: "error",
        },
      });
    },
    [notify]
  );

  const openWarning = useCallback(
    ({ options = {}, ...rest }: OpenNotificationType) => {
      return notify({ ...rest, options: { ...options, variant: "warning" } });
    },
    [notify]
  );

  const openDefault = useCallback(
    ({ options = {}, ...rest }: OpenNotificationType) => {
      return notify({ ...rest, options: { ...options, variant: "default" } });
    },
    [notify]
  );

  return {
    close: closeSnackbar,
    openDefault,
    openError,
    openSuccess,
    openWarning,
  };
};
