import jwtDecode from "jwt-decode";
import { UserIdentity } from "react-admin";

import { authStorage } from "constants/storage";

export const getIdentity = (): Promise<UserIdentity> => {
  try {
    const identity = jwtDecode(localStorage.getItem(authStorage.token) ?? "");
    const { email, firstName, lastName, sub: id } = identity as UserIdentity;
    return Promise.resolve({
      email,
      fullName: `${firstName} ${lastName}`,
      id,
    });
  } catch (error) {
    return Promise.reject(error);
  }
};
