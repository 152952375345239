import { Box, Card as CardUi, styled, Typography } from "@mui/material";
import CardActions from "@mui/material/CardActions";
import { useTranslate } from "react-admin";
import { Button } from "ui/atoms/button/Button";
import { Image as ImageUi, ImageType } from "ui/atoms/image/Image";

const Card = styled(CardUi)`
  height: 186px;
  display: flex;
  flex-direction: column;
`;

const Image = styled(ImageUi)`
  border-radius: ${({ theme }) => theme.shape.borderRadius}px
    ${({ theme }) => theme.shape.borderRadius}px 0 0;
  height: 140px;
`;

const Action = styled(CardActions)`
  height: 46px;
  border-top: 1px solid ${({ theme }) => theme.palette.grey[200]};
`;

const FooterContent = styled(Box)`
  display: flex;
  gap: 5px;
`;

type ImageCardType = ImageType & {
  onDeleteClick?: (fileIdentifier: string) => void;
  id?: string;
  icon?: React.ReactNode;
  title?: string;
};

export const ImageCard: React.FC<ImageCardType> = ({
  src,
  id,
  onDeleteClick,
  icon,
  title,
  ...rest
}) => {
  const translate = useTranslate();

  const onDelete = () => {
    id && onDeleteClick && onDeleteClick(id);
  };

  return (
    <Card {...rest}>
      <Image src={src} />
      <Action>
        <>
          {!!icon && !!title && (
            <FooterContent>
              {!!icon && icon}
              {!!title && <Typography variant="subtitle1">{title}</Typography>}
            </FooterContent>
          )}
          {!!onDeleteClick && (
            <Button onClick={onDelete} data-testid="deleteButton">
              {translate("shared.delete")}
            </Button>
          )}
        </>
      </Action>
    </Card>
  );
};
