import { get } from "lodash";
import { FileRejection } from "react-dropzone";
import { Translate } from "react-admin";

import { ERROR_TYPE_INVALID, getImgExt } from "constants/images";

export const IMAGEKIT_ENDPOINT = "https://ik.imagekit.io/masteos";

export const fileToBase64 = (file: File): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result?.toString() || "");
    reader.onerror = (error) => reject(error);
  });
};

/** @TODO See with the backend so that it sends us the complete url */
export const fromS3ID = (s3id: string, tr = "w-50"): Nullable<string> =>
  s3id ? `${IMAGEKIT_ENDPOINT}/tr:ar-3-2,${tr}/${s3id}` : null;

export const getKey = (file: File, ind: number): string => {
  return file.name + "_" + ind;
};

export const addFiles = (
  currentFile: File[],
  newfiles: File[],
  maxFiles?: number
): File[] => {
  let newFileList = [...currentFile, ...newfiles];

  if (maxFiles && newFileList.length > maxFiles) {
    newFileList = newFileList.slice(0, maxFiles);
  }

  return newFileList;
};

export const deleteFile = (files: File[], fileIdentifier: string): File[] => {
  const matches = fileIdentifier.match(/[^_]_([0-9]+)$/);
  const ind = get(matches, "1");

  if (!matches || !ind) {
    return files;
  }

  return files.filter((_file, index) => index !== parseInt(ind));
};

export const fileToImage = (file: File | string): Nullable<string> => {
  return typeof file === "string"
    ? fromS3ID(file, "w-500")
    : URL.createObjectURL(file);
};

export const hasErrorCode = (
  file: FileRejection,
  codeError: string
): boolean => {
  const invalidTypeFind = file.errors.find((error) => error.code === codeError);
  return !!invalidTypeFind;
};

export const formatMessageInvalidType = (
  filename: string[],
  translate: Translate
): string => {
  return translate("shared.images.error.invalidType", {
    ext: getImgExt(),
    filename: filename.join(", "),
    smart_count: filename.length,
  });
};

export const formatErrorMessage = (
  fileError: FileRejection[],
  translate: Translate
): string => {
  const errors: { [n: string]: string[] } = {
    [ERROR_TYPE_INVALID]: [],
  };

  fileError.forEach((file) => {
    if (hasErrorCode(file, ERROR_TYPE_INVALID)) {
      errors[ERROR_TYPE_INVALID].push(get(file, "file.name"));
    }
  });

  return formatMessageInvalidType(errors[ERROR_TYPE_INVALID], translate);
};
