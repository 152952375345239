import { TextInput, TextInputProps } from "react-admin";

export const TextArea: React.FC<TextInputProps> = (props) => (
  <TextInput
    sx={{ marginBottom: "4px", marginTop: "8px" }}
    fullWidth
    multiline
    maxRows={10}
    margin="none"
    variant="outlined"
    {...props}
  />
);
