import { NumberInputProps, NumberInput } from "react-admin";
import { InputAdornment } from "@mui/material";
import EuroIcon from "@mui/icons-material/Euro";

export const PriceInput: React.FC<NumberInputProps> = (props) => {
  return (
    <NumberInput
      {...props}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <EuroIcon />
          </InputAdornment>
        ),
      }}
    />
  );
};
