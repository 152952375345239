import React from "react";

import { SaveButton } from "components/save-button/SaveButton";
import { useTranslate } from "react-admin";
import { useFormContext } from "react-hook-form";
import { Box, Button, ButtonGroup, Theme, useMediaQuery } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useRealEstateActions } from "features/realEstates/edit/hooks/useRealEstateActions";
import { LoadingDropdown } from "ui/molecules/dropdown/Dropdown";
import { RealEstateAction } from "types/realEstate.types";

import { PublishButton } from "./PublishButton";

export const ActionButtons: React.FC = () => {
  const translate = useTranslate();
  const formContext = useFormContext();
  const { dropdownActions } = useRealEstateActions(formContext);
  const loading = dropdownActions.find((item) => item.loading);

  const { clearErrors } = formContext;

  const isSmall = useMediaQuery(
    (theme: Theme) => `(max-width:${theme.breakpoints.values.lg}px)`
  );

  return (
    <Box display="flex" flexDirection="row" gap={1}>
      <LoadingDropdown
        loading={!!loading}
        onSelect={(action) => (action as RealEstateAction).callback?.()}
        items={dropdownActions}
        keyExtractor={(action) => (action as RealEstateAction).name}
        renderItem={(action) => translate((action as RealEstateAction).name)}
      >
        <ButtonGroup variant="outlined" disabled={!!loading}>
          <Button variant="outlined">
            {translate(loading?.name ?? "realEstate.action.label")}
          </Button>
          <Button size="small">
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
      </LoadingDropdown>

      {!isSmall && <PublishButton />}

      <SaveButton
        onClick={() => {
          clearErrors();
        }}
        label={translate("ra.action.save")}
      />
    </Box>
  );
};
