import { authStorage } from "constants/storage";

export const checkError = ({ status }: { status: number }): Promise<void> => {
  if (status === 401) {
    localStorage.removeItem(authStorage.token);
    return Promise.reject();
  }
  // other error code (404, 500, etc.): no need to log out
  return Promise.resolve();
};
