import { useRecordContext, useTranslate } from "react-admin";
import { AccordionForm } from "ui/molecules/accordion-form/AccordionForm";
import { TextArea } from "ui/atoms/textarea/TextArea";
import { getInternalNoteTemplate } from "features/realEstates/utils/getInternalNoteTemplate";

import { RealEstateEditAccordeonFormProps } from "./RealEstateEditAccordeonFormProps";

export const InternalNoteForm: React.FC<RealEstateEditAccordeonFormProps> = ({
  defaultExpanded,
}) => {
  const record = useRecordContext();
  const translate = useTranslate();
  return (
    <AccordionForm
      defaultExpanded={defaultExpanded}
      title={translate("realEstate.form.internalNote")}
    >
      <TextArea
        fullWidth
        defaultValue={getInternalNoteTemplate(record.lang)}
        label="realEstate.input.internalNote"
        source="internalNote"
        maxRows={undefined}
      />
    </AccordionForm>
  );
};
