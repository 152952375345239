import React, { useEffect, useState } from "react";

import { useFormContext } from "react-hook-form";
import { SelectArrayInput, TextInput } from "react-admin";
import { Select } from "ui/molecules/select/Select";

import { ROLES } from "constants/user";
interface UserFormFieldsProps {
  isEdit?: boolean;
  cityManagers:
    | {
        label: string;
        value: string;
      }[]
    | undefined;
}

export const UserFormFields: React.FC<UserFormFieldsProps> = ({
  cityManagers,
  isEdit,
}) => {
  const { getValues } = useFormContext();
  const [showCityManager, setShowCityManager] = useState(false);
  const handleCityManagerVisibility = () =>
    setTimeout(() => {
      const roles = getValues("roles");
      setShowCityManager(roles && roles.includes("hunter"));
    });
  useEffect(() => {
    handleCityManagerVisibility();
  });

  const sortedRoles = [...ROLES].sort((a, b) => (a.label > b.label ? 1 : -1));
  return (
    <>
      <TextInput
        margin="none"
        variant="outlined"
        label="user.input.firstname"
        source="firstName"
      />

      <TextInput
        margin="none"
        variant="outlined"
        label="user.input.lastname"
        source="lastName"
      />

      <TextInput
        margin="none"
        variant="outlined"
        label="user.input.email"
        source="email"
        disabled={isEdit}
      />

      <SelectArrayInput
        label="user.input.role"
        choices={sortedRoles}
        optionText="label"
        optionValue="value"
        source="roles"
        variant="outlined"
        onChange={handleCityManagerVisibility}
      />

      {!!showCityManager && (
        <Select
          label="user.input.cityManager"
          choices={cityManagers}
          optionText="label"
          optionValue="value"
          source="cityManagerId"
          variant="outlined"
        />
      )}
    </>
  );
};
