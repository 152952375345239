import React from "react";

import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button } from "@mui/material";
import {
  useTranslate,
  Form as RAForm,
  Create as RACreate,
  useNotify,
  useStore,
  useRedirect,
  RaRecord,
  useEditContext,
} from "react-admin";
import { styled } from "@mui/material/styles";
import { Modal } from "ui/organisms/modal/Modal";
import { GooglePlacesAutocomplete } from "components/google-places-autocomplete/GooglePlacesAutocomplete";
import { Select } from "ui/molecules/select/Select";
import { formatFullStreet } from "utils/form/form.utils";
import { SaveButton } from "components/save-button/SaveButton";
import { getInternalNoteTemplate } from "features/realEstates/utils/getInternalNoteTemplate";

import { LANGS_DATA, PROPERTY_KIND_DATA } from "constants/real-estates";

import { propertyForm } from "./AddRealEstate.validator";

const FooterContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
`;

const Form = styled(RAForm)`
  box-shadow: none;
`;

const Create = styled(RACreate)`
  & .MuiPaper-root {
    box-shadow: none;
    border: none;
  }
`;

const ButtonsContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: ${(props) => props.theme.spacing(2)};
`;

interface ErrorResponse {
  body?: { message: string };
}

export const AddRealEstateModalForm: React.FC = () => {
  const [open, setOpenModalCreate] = useStore(
    "realEstate.edit.show_add_modal",
    false
  );
  const navigate = useNavigate();
  const { record } = useEditContext();
  const redirect = useRedirect();
  const translate = useTranslate();
  const notify = useNotify();

  const isDuplicating = !!record;

  const onError = (err: unknown) => {
    notify((err as ErrorResponse)?.body?.message || "realEstate.create.error", {
      type: "error",
    });
  };

  const onSuccess = (response: RaRecord) => {
    notify(isDuplicating ? "realEstate.duplicate.ok" : "realEstate.create.ok", {
      type: "success",
    });
    redirect("edit", "real-estates", response.id);
    isDuplicating && navigate(0);
    closeModal();
  };

  const closeModal = () => setOpenModalCreate(false);

  const handleTransform = (data: RaRecord) => {
    const formattedRecord = {
      ...data,
      address: {
        ...data.address,
        street: formatFullStreet(data.address),
      },
      internalNote: getInternalNoteTemplate(data.lang),
    } as RaRecord;

    if (!isDuplicating) {
      return formattedRecord;
    }

    const { _id, lang, address, type, internalNote } = formattedRecord;
    return {
      address,
      fromRealEstateId: _id,
      internalNote,
      lang,
      type,
    };
  };

  return (
    <Create
      record={record}
      transform={handleTransform}
      mutationOptions={{ onError, onSuccess }}
    >
      <Form id="create-property-form" resolver={yupResolver(propertyForm)}>
        <Modal
          open={open}
          onClose={closeModal}
          title={translate(
            isDuplicating
              ? "realEstate.action.duplicate.form_title"
              : "realEstate.add"
          )}
          footer={
            <FooterContainer>
              <ButtonsContainer>
                <Button variant="text" onClick={closeModal}>
                  {translate("ra.action.cancel")}
                </Button>
                <SaveButton
                  alwaysEnabled={true}
                  name="create-property-form"
                  variant="text"
                  form="create-property-form"
                  label={translate(
                    isDuplicating
                      ? "realEstate.action.duplicate.submit"
                      : "ra.action.add"
                  )}
                />
              </ButtonsContainer>
            </FooterContainer>
          }
        >
          <Container>
            <Select
              label="realEstate.input.propertyKind"
              choices={PROPERTY_KIND_DATA}
              source="type"
              variant="outlined"
              inputProps={{ "data-testid": "propertyKind-select" }}
            />

            <GooglePlacesAutocomplete
              source="address"
              defaultValue={record ? formatFullStreet(record?.address) : ""}
            />

            <Select
              label="realEstate.input.lang"
              choices={LANGS_DATA}
              source="lang"
              variant="outlined"
              inputProps={{ "data-testid": "lang-select" }}
            />
          </Container>
        </Modal>
      </Form>
    </Create>
  );
};
